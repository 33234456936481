import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'

import colors from '../../themes/colors'

const DoubleSwitch = styled(Switch)(() => ({
  width: 52,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 6,
    padding: 0,
    transform: 'translateX(1px)',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.primary.light,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: colors.primary.main,
    width: 22,
    height: 22,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: colors.primary.light,
    borderRadius: 20 / 2,
  },
}))

export default DoubleSwitch
