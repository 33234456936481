import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import includeBank from "../../../fns/includeBank"
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const BankStatus = (inputs) => {
  const classes = useStyles()

  const cardsWallet = inputs.cardsWallet
  const cardsAnalyze = inputs.cardsAnalyze
  cardsWallet.concat(cardsAnalyze)

  const roundThou = (val) => {
    if (val >= 100000) {
      return "$100k+"
    }
    else {
      return ("$" + Math.round(val/1000) + "k")
    }
  }

  return <>
    {includeBank(cardsWallet, "boa") ?
      <Box>
        <Typography variant="h5">Bank of America Status:</Typography>
        <Box className={classes.spacingHalf}>
          <Typography variant="body1">{questions.boaAccount}</Typography>
          <YesNo bool={inputs.userInput.inputViews.boaAccount}/>
        </Box>
        {inputs.userInput.inputViews.boaAccount ?
          <>
            <Box className={classes.spacingHalf}>
              <Typography variant="body1">{questions.boaAccountBoa}</Typography>
              <Typography variant="body1" className={classes.fontBold}>{roundThou(inputs.userInput.boa.cash)}</Typography>
            </Box>
            <Box className={classes.spacingHalf}>
              <Typography variant="body1">{questions.boaAccountMerrill}</Typography>
              <Typography variant="body1" className={classes.fontBold}>{roundThou(inputs.userInput.boa.investments)}</Typography>
            </Box>
          </>
        :
          null
        }
        <Box className={classes.spacingHalf}>
          <Typography variant="body1">
            {inputs.userInput.inputViews.boaAccount ?
              questions.boaAccountNew_add
            :
              questions.boaAccountNew_new
            }
          </Typography>
          <YesNo bool={inputs.userInput.inputViews.boaAccountNew}/>
        </Box>
        {inputs.userInput.inputViews.boaAccountNew ?
          <>
            <Box className={classes.spacingHalf}>
              <Typography variant="body1">
                {inputs.userInput.inputViews.boaAccount ?
                  questions.boaAccountNewBoa_add
                :
                  questions.boaAccountNewBoa_new
                }
              </Typography>
              <Typography variant="body1" className={classes.fontBold}>{roundThou(inputs.userInput.boa.cashNew)}</Typography>
            </Box>
            <Box className={classes.spacingHalf}>
              <Typography variant="body1">
                {inputs.userInput.inputViews.boaAccount ?
                  questions.boaAccountNewMerrill_add
                :
                  questions.boaAccountNewMerrill_new
                }
              </Typography>
              <Typography variant="body1" className={classes.fontBold}>{roundThou(inputs.userInput.boa.investmentsNew)}</Typography>
            </Box>
          </>
        :
          null
        }
      </Box>
    :
      <></>
    }
  </>
}

export default BankStatus
