import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesEntertainment = (templateData) => {
  const inputs = [
    {
      db: "shared_touristAttractions",
      disp: "Tourist Attractions",
    },
    {
      db: "shared_amusementParks",
      disp: "Amusement Parks",
    },
    {
      db: "entertainment_live",
      disp: "Live Entertainment",
    },
    {
      db: "entertainment_movies",
      disp: "Movie Theaters",
    },
    {
      db: "fitnessClubs",
      disp: "Fitness Clubs",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesEntertainment
