import includeBank from './includeBank'
import getBanksActiveCash from './getBanksActiveCash'
import getBanksActivePoints from './getBanksActivePoints'

const getInputViewSkips = (inputs) => {
  const cardsActive = [
    ...inputs.cardsWallet,
    ...inputs.cardsAnalyze,
  ]

  const includeBoa = includeBank(cardsActive, "boa")
  const includeSofi = includeBank(cardsActive, "sofi")
  const banksCash = getBanksActiveCash(cardsActive)
  const banksPoints = getBanksActivePoints(cardsActive)

  return {
    bankStatus: !includeBoa,
    sofiDirectDeposit: !includeSofi,
    multipliersCash: !Boolean(banksCash.length),
    multipliersPoints: !Boolean(banksPoints.length),
  }
}

export default getInputViewSkips
