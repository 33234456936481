const colors = {
  grey: '#aaa',
  primary: {
    main: '#00796b',
    light: '#79a7b5',
    dark: '#00544a'
  },
  purchases: {
    basic: '#000',
    bronze: '#cd7f32',
    silver: '#c0c0c0',
    gold: '#ffc904'
  },
  secondary: {
    main: "#ff6488",
  },
}

export default colors
