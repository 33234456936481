import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Redirect} from "react-router-dom"

import axios from "axios"
import {toast} from "react-toastify"

import {loadingToggle} from "../../store/actions/appActions"
import {changeName, changeEmail, changePassword, updateName} from "../../store/actions/authActions"
import {urlApi} from "../../api"

import {Typography, TextField, Button} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles"

import inputLimits from '../../constants/inputLimits'
import isEmail from '../../fns/isEmail'

const useStyles = makeStyles({
  divider: {
    height: "50px",
  },
  formStyle: {
    margin: "0px auto",
    padding: "30px",
    borderRadius: "9px",
    boxShadow: "0px 0px 12px -3px #000",
  },
  spacing: {
    marginTop: "20px",

  },
})

const Account = (props) => {
  const classes = useStyles()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    emailPassword: "",
    passwordCurrent: "",
    passwordNew1: "",
    passwordNew2: "",
  })
  const [inputs, setInputs] = useState({
    name: auth.name,
    email: "",
    emailPassword: "",
    passwordCurrent: "",
    passwordNew1: "",
    passwordNew2: "",
  })


  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    inputs[typeUser] = val
    setInputs({...inputs})
  }

  const handleSubmitEmail = (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    var errorEmail = ""
    var errorPassword = ""
    if (!isEmail(inputs.email)) {
      errorEmail = "Please enter a valid email address"
    }
    if (!inputs.emailPassword.length) {
      errorPassword = "Please enter your password"
    }
    setErrors({
      ...errors,
      email: errorEmail,
      emailPassword: errorPassword,
    })
    if (errorEmail || errorPassword) {
      dispatch(loadingToggle(false))
    }
    else {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
            dispatch(changeEmail({
              email: inputs.email,
              password: inputs.emailPassword,
              recaptchaToken: token,
            }))
            setInputs({
              ...inputs,
              emailPassword: "",
            })
        })
      })
    }
  }

  const handleSubmitName = (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    var errorName = ""
    if (!inputs.name) {
      errorName = "Please enter a new name"
    }
    if (errorName) {
      setErrors({
        ...errors,
        name: errorName,
      })
      dispatch(loadingToggle(false))
    }
    else {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
            dispatch(changeName({
              name: inputs.name,
              recaptchaToken: token,
            }))
            setInputs({
              ...inputs,
            })
        })
      })
    }
  }

  const handleSubmitPassword = (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    var errorPassword = ""
    var errorPw1 = ""
    var errorPw2 = ""
    if (!inputs.passwordCurrent) {
      errorPassword = "Please enter your current password"
    }
    if (inputs.passwordNew1) {
      if (inputs.passwordNew1.length < inputLimits.password.min) {
        errorPw1 = ("Please enter a password at least " + inputLimits.password.min + " characters long")
      }
      if (inputs.passwordNew2 !== inputs.passwordNew1) {
        errorPw2 = "Passwords do not match"
      }
    }
    else {
      errorPw1 = "Please enter a new password"
    }
    setErrors({
      ...errors,
      passwordCurrent: errorPassword,
      passwordNew1: errorPw1,
      passwordNew2: errorPw2,
    })
    if (errorPassword || errorPw1 || errorPw2) {
      dispatch(loadingToggle(false))
    }
    else {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
          dispatch(changePassword({
            password: inputs.passwordCurrent, // bcrypt
            passwordNew: inputs.passwordNew1, // bcrypt
            recaptchaToken: token,
          }))
          setInputs({
            ...inputs,
            passwordCurrent: "",
            passwordNew1: "",
            passwordNew2: "",
          })
        })
      })
    }
  }

  useEffect(() => {
    props.socket.on('user', (updatedUser) => {
      setInputs({
        ...inputs,
        name: updatedUser.name,
        email: updatedUser.email,
      })
      if (auth.name !== updatedUser.name) {
        dispatch(updateName(updatedUser.name))
      }

    })

    axios
    .post(`${urlApi}/user/getEmail`, {}, {"withCredentials": true})
    .then(resp => {
      setInputs({
        ...inputs,
        email: resp.data,
      })
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!auth.name) return <Redirect to="/"/>

  const divider = () => (
    <div
      className={classes.divider}
    >
    </div>
  )

  return ( <>
    <Typography variant="h6">
      <form
        className={classes.formStyle}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmitName}
      >

        Change Name
        <TextField
          id="enter-name"
          className={classes.spacing}
          label="Name"
          variant="outlined"
          fullWidth
          value = {inputs.name}
          onChange = {(event) => changeVal(event, "name")}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
        <Button
          className={classes.spacing}
          variant="contained"
          color="primary"
          type="submit"
        >
          Save
        </Button>

      </form>
      {divider()}
      <form
        className={classes.formStyle}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmitEmail}
      >
        Change Email
          <TextField
            id="enter-email"
            className={classes.spacing}
            label="Email"
            variant="outlined"
            fullWidth
            value = {inputs.email}
            onChange = {(event) => changeVal(event, "email")}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <TextField
            id="enter-email-password"
            className={classes.spacing}
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            value = {inputs.emailPassword}
            onChange = {(event) => changeVal(event, "password", "emailPassword")}
            error={Boolean(errors.emailPassword)}
            helperText={errors.emailPassword}
          />
          <Button
            className={classes.spacing}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>

      </form>
      {divider()}
      <form
        className={classes.formStyle}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmitPassword}
      >
        Change Password
          <TextField
            id="enter-password-current"
            className={classes.spacing}
            type="password"
            label="Current Password"
            variant="outlined"
            fullWidth
            value = {inputs.passwordCurrent}
            onChange = {(event) => changeVal(event, "password", "passwordCurrent")}
            error={Boolean(errors.passwordCurrent)}
            helperText={errors.passwordCurrent}
          />
          <TextField
            id="enter-password-new1"
            className={classes.spacing}
            type="password"
            label="New Password"
            variant="outlined"
            fullWidth
            value = {inputs.passwordNew1}
            onChange = {(event) => changeVal(event, "password", "passwordNew1")}
            error={Boolean(errors.passwordNew1)}
            helperText={errors.passwordNew1}
          />
          <TextField
            id="enter-password-new2"
            className={classes.spacing}
            type="password"
            label="Re-enter New Password"
            variant="outlined"
            fullWidth
            value = {inputs.passwordNew2}
            onChange = {(event) => changeVal(event, "password", "passwordNew2")}
            error={Boolean(errors.passwordNew2)}
            helperText={errors.passwordNew2}
          />
          <Button
            className={classes.spacing}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>

      </form>
    </Typography>
  </> )
}

export default Account