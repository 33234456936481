
import {useState, useEffect} from 'react'

import {Typography} from "@material-ui/core"
import { List, ListItem, ListItemIcon } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconQuestion from '@material-ui/icons/Help'

import cards from '../../../constants/cards'
import questions from '../../../constants/questions'
import getInputViewSkips from '../../../fns/getInputViewSkips'
import {useStyles} from '../../../themes/styles'

import DialogCard from './dialogCard'

const CardsAnalyze = (templateData) => {
  const classes = useStyles()

  const [detail, setDetail] = useState(null)

  const handleChangeCard = (event) => {
    var cardsChecked = templateData.pageState.state.inputs.cardsAnalyze
    if (event.target.checked) {
      cardsChecked.push(event.target.name)
    }
    else {
      cardsChecked.splice(cardsChecked.indexOf(event.target.name), 1)
    }
    templateData.pageState.state.skipViews = getInputViewSkips(templateData.pageState.state.inputs)
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const handleDetails = (card) => {
    setDetail(card)
  }

  useEffect(() => {
    let cardsAnalyze = templateData.pageState.state.inputs.cardsAnalyze
    for (let iWallet = 0; iWallet < templateData.pageState.state.inputs.cardsWallet.length; iWallet++) {
      if (cardsAnalyze.includes(templateData.pageState.state.inputs.cardsWallet[iWallet])) {
        cardsAnalyze.splice(cardsAnalyze.indexOf(templateData.pageState.state.inputs.cardsWallet[iWallet]),1)
      }

    }
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cardsFiltered = cards.filter(card => (
    (
      (card.tier >= 0)
      &&
      (templateData.pageState.state.inputs.tier.level >= card.tier)
    )
    ||
    templateData.pageState.state.inputs.cardsWallet.includes(card.db)
  ))

  const cardsCustomMax = (templateData.pageState.state.inputs.tier.level - Math.floor(templateData.pageState.state.inputs.tier.level)) * 100
  const isCustom = (templateData.pageState.state.inputs.tier.level !== Math.floor(templateData.pageState.state.inputs.tier.level))

  return <form>
    <DialogCard open={Boolean(detail)} setOpen={setDetail} card={detail}/>
    <Typography variant="h5">
      {questions.cardsAnalyze}
    </Typography>
    {isCustom ?
      <Typography variant="h6">
        Select up to {Math.round(cardsCustomMax)} cards
      </Typography>
      :
      <></>
    }
    <FormGroup
    className={classes.spacing}
    >
    <List>
      {cardsFiltered.map((step, index) => (
        <ListItem>
        <FormControlLabel
        key={step.db}
        control={<Checkbox
          checked={
            templateData.pageState.state.inputs.cardsAnalyze.includes(step.db)
            ||
            templateData.pageState.state.inputs.cardsWallet.includes(step.db)
          }
          disabled={
            templateData.pageState.state.inputs.cardsWallet.includes(step.db)
            ||
            (
              isCustom
              &&
              templateData.pageState.state.inputs.cardsAnalyze.length >= cardsCustomMax
              &&
              !templateData.pageState.state.inputs.cardsAnalyze.includes(step.db)
            )
          }
          name={step.db}
          onChange={handleChangeCard}
        />}
        label={step.display}
        />
        <ListItemIcon className={classes.clickBlack0}>
          <IconQuestion
          fontSize="small"
          color="primary"
          onClick={() => handleDetails(step)}
          className={classes.clickBlack}
          />
        </ListItemIcon>
        </ListItem>
      ))}
      </List>
    </FormGroup>
  </form>
}

export default CardsAnalyze
