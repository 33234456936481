import React, {useEffect} from 'react'
import {useDispatch} from "react-redux"

import PropTypes from 'prop-types'

import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import calcSummary from '../../fns/calcSummary'
import {loadingToggle} from "../../store/actions/appActions"

import Inputs from './resultViews/Inputs'
import Summary from './resultViews/Summary'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

 const Result = (templateData) => {
  const dispatch = useDispatch()

  const summary = calcSummary(templateData.pageState.state)

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  
  useEffect(() => {
    dispatch(loadingToggle(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Results" {...a11yProps(0)} />
          <Tab label="Inputs" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Summary pageState={templateData.pageState} summary={summary}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Inputs {...templateData.pageState.state}/>
      </TabPanel>
    </Box>
  )
}

export default Result
