import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const Security = (inputs) => {
  const classes = useStyles()

  return <Box>
    <Typography variant="h5">Travel</Typography>
    <Box className={classes.spacingHalf}>
      <Typography variant="body1">{questions.travelInternationally}</Typography>
      <YesNo bool={inputs.userInput.inputViews.travelInternationally}/>
    </Box>
    {inputs.userInput.inputViews.travelInternationally ?
      <Box className={classes.spacingHalf}>
        <Typography variant="body1">{questions.securityGlobalEntry}</Typography>
        <YesNo bool={inputs.userInput.security.globalEntry}/>
      </Box>
    :
        null
    }
    {!inputs.userInput.security.globalEntry ?
      <Box className={classes.spacingHalf}>
        <Typography variant="body1">{questions.securityPreCheck}</Typography>
        <YesNo bool={inputs.userInput.security.tsaPreCheck}/>
      </Box>
    :
      null
    }
    <Box className={classes.spacingHalf}>
      <Typography variant="body1">{questions.securityClear}</Typography>
      <YesNo bool={inputs.userInput.security.clear}/>
    </Box>
  </Box>
}

export default Security
