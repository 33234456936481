const getBanksActivePoints = (cardsActive) => {
  const ccsPoints = [
    {
      db: "amex",
      disp: "American Express",
      min: 1,
      cards: [
        "amex_breadRewards",
        "amex_green",
        "amex_gold",
        "amex_platinum",
      ],
    },
    /*{
      db: "boa",
      disp: "Bank of America",
      min: 1,
      cards: [
        "boa_travel",
      ],
    },*/
    {
      db: "cap1",
      disp: "Capital One",
      min: 1,
      cards: [
        "cap1_ventureOne",
        "cap1_venture",
        "cap1_ventureX",
      ],
    },
    {
      db: "chase",
      disp: "Chase",
      min: 1,
      cards: [
        "chase_sapphirePreferred",
        "chase_sapphireReserve",
      ],
    },
    {
      db: "citi",
      disp: "Citi",
      min: 1,
      cards: [
        "citi_rewardsPlus",
        "citi_premier",
      ],
    },
    {
      db: "usbank",
      disp: "US Bank",
      min: 1,
      cards: [
        "usbank_flexPerksGold",
        "usbank_altitudeConnect",
        "usbank_altitudeReserve",
      ],
    },
    {
      db: "wf",
      disp: "Wells Fargo",
      min: 1,
      cards: [
        "wf_autograph",
        "wf_autographJourney",
      ],
    },
  ]

  let banksActivePoints = []
  for (let iBank = 0; iBank < ccsPoints.length; iBank++) {
    for (let iCard = 0; iCard < ccsPoints[iBank].cards.length; iCard++) {
      if (cardsActive.includes(ccsPoints[iBank].cards[iCard])) {
        banksActivePoints.push(ccsPoints[iBank])
        break
      }
    }
  }
  return banksActivePoints
}

export default getBanksActivePoints
