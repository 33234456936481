const includeBank = (cards, bankCheck) => {
    for (let iCard = 0; iCard < cards.length; ++iCard) {
      let bankCard = cards[iCard].split("_")[0]
      if (bankCard === bankCheck) {
        return true
      }
    }
    return false
  }
  
  export default includeBank
  