import React from 'react'
import {useSelector} from "react-redux"

import {Typography, Box} from "@material-ui/core"

import {useStyles} from '../../themes/styles'

import Login from './Login'

const Home = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  return ( <>
    <Box className={classes.spacing}>
      <Typography variant="h2" align="center" className={classes.fontScript}>Stack My Deck</Typography>
      <Typography variant="h4" align="center" className={classes.fontScript0}>Real Numbers, Real Value.</Typography>
    </Box>
    {true || auth.name ?
      <></>
    :
      <Box className={classes.spacing}>
        <Login {...props}/>
      </Box>
    }
  </> )
}

export default Home
