import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import includeBank from "../../../fns/includeBank"
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const Sofi = (inputs) => {
  const classes = useStyles()

  const cardsWallet = inputs.cardsWallet
  const cardsAnalyze = inputs.cardsAnalyze
  const includeSofi = includeBank(cardsWallet, "sofi") || includeBank(cardsAnalyze, "sofi")

  return <>
    {includeSofi ?
        <Box>
            <Typography variant="h5">SoFi Account:</Typography>
                <Box className={classes.spacingHalf}>
                    <Typography variant="body1">{questions.sofiDirectDeposit}</Typography>
                    <YesNo bool={inputs.userInput.inputViews.sofiDirectDeposit}/>
                </Box>
        </Box>
    :
        <></>
    }
  </>
}

export default Sofi
