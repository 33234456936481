import {Typography} from "@material-ui/core"

const CreditScore = (props) => {

  return ( <>
    <Typography>
      We understand that not everybody has great credit, and that's okay.
      Having a good credit score opens up a lot of possibilities and allows you to get the best credit cards.
      At this time we're focused on recommending the best credit cards available.
      <br/>
      <br/>
      If you cannot qualify for any of the cards that we recommend to you, it would be helpful improve your credit score.
      Secured credit cards are a great way to do that.
      Any secured credit card should be beneficial.
      <br/>
      <br/>
      We will soon be implementing credit card recommendations for users with less-than-stellar credit.
    </Typography>
  </> )
}

export default CreditScore
