const getBanksActiveCash = (cardsActive) => {
  const ccsCash = [
    {
      db: "amex",
      disp: "American Express",
      cards: [
        "amex_blueCashEveryday",
        "amex_blueCashPreferred",
        "amex_breadCashback",
      ],
    },
    // {
    //   db: "boa",
    //   disp: "Bank of America",
    //   cards: [
    //     "boa_unlimitedCash",
    //     "boa_customizedCash",
    //     "boa_premium",
    //     "boa_premiumElite",
    //   ],
    // },
    {
      db: "cap1",
      disp: "Capital One",
      cards: [
        "cap1_quicksilver",
        "cap1_savorOne",
        "cap1_savor",
      ],
    },
    {
      db: "chase",
      disp: "Chase",
      cards: [
        "chase_freedom",
        "chase_freedomFlex",
        "chase_freedomUnlimited",
      ],
    },
    {
      db: "citi",
      disp: "Citi",
      cards: [
        "citi_doubleCash",
        "citi_customCash",
      ],
    },
    {
      db: "discover",
      disp: "Discover",
      cards: [
        "discover_it",
      ],
    },
    {
      db: "usbank",
      disp: "US Bank",
      cards: [
        "usbank_altitudeGo",
        "usbank_cashPlus",
      ],
    },
    {
      db: "wf",
      disp: "Wells Fargo",
      cards: [
        "wf_activeCash",
      ],
    },
  ]

  let banksActiveCash = []
  for (let iBank = 0; iBank < ccsCash.length; iBank++) {
    for (let iCard = 0; iCard < ccsCash[iBank].cards.length; iCard++) {
      if (cardsActive.includes(ccsCash[iBank].cards[iCard])) {
        banksActiveCash.push(ccsCash[iBank])
        break
      }
    }
  }
  return banksActiveCash
}

export default getBanksActiveCash
