import * as React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import theme from '../../../themes/_main'

import Terms from '../Terms'

const DialogTerms = (props) => {
  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>Terms of Use</DialogTitle>
        <DialogContent>
          <Terms/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default DialogTerms