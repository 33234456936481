import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link, Redirect} from "react-router-dom"

import axios from "axios"
import moment from "moment"
import {toast} from "react-toastify"

import {List, ListItem} from '@material-ui/core'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import {urlApi} from "../../api"
import {loadingToggle} from "../../store/actions/appActions"
import {useStyles} from '../../themes/styles'

import DividerMargin from '../styled/DividerMargin'

const humanReadableFromType = (fullAnalysis) => {
  return (fullAnalysis ? "Full Deck" : "Next Card")
}

const History = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [results, setResults] = React.useState(null)

  useEffect(() => {
    props.socket.on('history', (updatedResults) => {
      setResults(updatedResults)
    })

    if (auth.name) {
      dispatch(loadingToggle(true))
      axios
      .post(`${urlApi}/analysis/getResults`, {}, {"withCredentials": true})
      .then(resp => {
        setResults(resp.data)

        dispatch(loadingToggle(false))
      })
      .catch(error => {
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        })
        dispatch({
          type: "LOADING_TOGGLE",
          isLoading: false,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!auth.name) return <Redirect to="/"/>

  return (
    <>
      <Typography variant="h4" sx={{}}>Completed Analyses</Typography>
      {results == null ?
        null
      :
        (results.length ?
          <div>
            <DividerMargin/>
            <List>
              {results.map((step, index) => (
                <div key={"result_" + index}>
                <ListItem className="clickBlack" component={Link} to={"/analysis/" + step._id}>
                  <Box style={{textDecoration: 'none'}} className={classes.spacing + classes.clickBlack}>
                    <Typography className={classes.clickBlack} variant="h5">{humanReadableFromType(step.tier.fullAnalysis) + " Analysis"}</Typography>
                    <Typography className={classes.clickBlack} variant="h6">{"Completed " + moment(step.dateCompleted).fromNow()}</Typography>
                  </Box>
                </ListItem>
                <DividerMargin/>
                </div>
              ))}
            </List>
          </div>
        :
          <>
            <Typography variant="h5">
              You have not completed any analyses. Visit the {" "}
              <Link to="/analysis">
                new analysis
              </Link>
              {" "} page to purchase a new analysis or complete an analysis you've already purchased.
            </Typography>
          </>
        )
      }
    </>
  )
}

export default History
