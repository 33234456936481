import {makeStyles} from "@material-ui/styles"

import colors from './colors'

export const useStyles = makeStyles({
  blockFull: {
    width: "100%",
    flexGrow: 1,
  },
  blockPrimary: {
    backgroundColor: colors.primary.main,
    minHeight: '20px',
  },
  clickBlack: {
    color: "#000",
    'text-decoration': 'none',
    "&:hover": {
      cursor: "pointer",
    }
  },
  clickGrey: {
    color: colors.grey,
    "&:hover": {
      cursor: "pointer",
    }
  },
  clickWhite: {
    color: "#fff",
    'text-decoration': 'none',
    "&:hover": {
      cursor: "pointer",
    }
  },
  colorLight: {
    backgroundColor: colors.primary.light,
    color: "#fff",
    "&:hover": {
      backgroundColor: colors.primary.dark,
    }
  },
  contentStyle: {
    margin: "30px auto",
  },
  divider: {
    margin: "10px"
  },
  dividerFull: {
    width: "100%",
  },
  dotActive: {
    backgroundColor: colors.primary.main
  },
  flexGrow: {
    "flex-grow": 1
  },
  flexParentPage: {
    "min-height": "100vh",
    display: "flex",
    "flex-flow": "column",
  },
  flexStatic: {
    flex: "0 1 auto",
  },
  flexStretch: {
    flex: "1 1 auto",
  },
  fontBold: {
    "font-weight": 900,
  },
  fontItalic: {
    "font-style": "italic",
  },
  fontScript: {
    "font-family": "'Dancing Script', cursive;",
    "font-weight": 900,
  },
  fullList: {
    width: 'auto',
  },
  image: {
    width: "100%",
  },
  linkStyle: {
    color: "white",
    textDecoration: "none",
  },
  list: {
    width: 250,
  },
  name: {
    "text-align": "right",
  },
  root: {
    backgroundColor: "#fff"
  },
  rootHeader: {
    flexGrow: 1,
    "padding-left": "10px",
  },
  shadowBox: {
    margin: "0px auto",
    padding: "30px",
    borderRadius: "9px",
    boxShadow: "0px 0px 12px -3px #000",
  },
  spacing: {
    marginTop: "20px",
  },
  spacingBottom: {
    marginBottom: "20px",
  },
  spacingHalf: {
    marginTop: "10px",
  },
  spacingMinus: {
    marginTop: "-20px",
  },
  spacingLeft: {
    "padding-left": "10px",
  },
  spacingRight: {
    "margin-right": "10px",
  },
  spreadBox: {
    display: "flex",
    width: "100%",
  },
  spreadBoxCenter: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  spreadBoxFull: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stretch: {
    height: "100%"
  },
  tableAlignTop: {
    "vertical-align": "top",
    "padding-top": "10px",
  },
  tableColumnSpacing: {
    "padding-right": "10px",
  },
  tableRow: {
    "padding-top": "100px",
    "padding=bottom": "100px",
  },
  textGrey: {
    color: colors.grey,
  },
  textNeg: {
    color: colors.secondary.main
  },
  textPos: {
    color: colors.primary.main
  },
})
