import * as React from 'react'
import {useEffect} from 'react'

import {Typography, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Slider from '@mui/material/Slider'

import questions from '../../../constants/questions'
import getBanksActiveCash from '../../../fns/getBanksActiveCash'
import {useStyles} from '../../../themes/styles'

const MultipliersCash = (templateData) => {
  const classes = useStyles()

  const banksActive = getBanksActiveCash([...templateData.pageState.state.inputs.cardsWallet, ...templateData.pageState.state.inputs.cardsAnalyze])

  const calculateValue = (val) => {
    return (val * 0.05) + 0.95
  }

  const dbToSlider = (valDb) => {
    return Math.round((20 * valDb) - 19)
  }

  const handleChangeSlider = (field, val) => {
    const valScaled = calculateValue(val)
    let vals = templateData.pageState.state.inputs.userInput.multipliers.cash
    if (isNaN(Number(valScaled))) {
      vals[field] = 1
    }
    else {
      vals[field] = valScaled
    }
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const handleGiftCards = (acceptGC) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.giftCards = acceptGC
    templateData.pageState.setState({
      ...templateData.pageState.state,
      disableNext: false,
    })
  }

  const valueFormat = (val) => {
    return val.toFixed(2)
  }

  const valueLabelFormat = (val) => {
    let scaledVal = (val * 0.05) + 0.95
    return scaledVal.toFixed(2)
  }

  useEffect(() => {
    if (templateData.pageState.state.inputs.userInput.inputViews.giftCards == null) {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: true,
      })
    }
    else {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <form>
    <Typography variant="h5">
      {questions.giftCards}
    </Typography>
    <FormGroup
    className={classes.spacing}
    >
      <Box
      component="span"
      className={classes.spreadBoxCenter}
      >
        <Button
          variant="contained"
          color={templateData.pageState.state.inputs.userInput.inputViews.giftCards === true ? "default" : "secondary"}
          onClick={() => handleGiftCards(false)}
        >
          No
        </Button>
        <Button
          variant="contained"
          color={templateData.pageState.state.inputs.userInput.inputViews.giftCards === false ? "default" : "primary"}
          onClick={() => handleGiftCards(true)}
        >
          Yes
        </Button>
      </Box>
      {templateData.pageState.state.inputs.userInput.inputViews.giftCards !== true ?
        <></>
      :
        <Box
        className={classes.spacing}
        >
          <Box
          className={classes.spacing}
          >
            <Typography variant="h5">{questions.giftCardsValue}</Typography>
            <Typography variant="h6">If you're unsure what to put here, we recommend using the minimum values</Typography>
          </Box>
          <Box
          className={classes.spacing}
          >
            {banksActive.map((step, index) => (
              <Box key={step.db} sx={{ width: 250 }}>
                <Typography id={step.db} gutterBottom>
                  {step.disp}: x {valueFormat(templateData.pageState.state.inputs.userInput.multipliers.cash[step.db])}
                </Typography>
                <Slider
                  value={dbToSlider(templateData.pageState.state.inputs.userInput.multipliers.cash[step.db])}
                  min={1}
                  step={1}
                  max={6}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                  onChange={event => handleChangeSlider(step.db, event.target.value)}
                  valueLabelDisplay="auto"
                  aria-labelledby={step.db}
                />
              </Box>
            ))}
          </Box>
        </Box>
      }

    </FormGroup>
  </form>
}

export default MultipliersCash
