import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import getBanksActivePoints from '../../../fns/getBanksActivePoints'
import {useStyles} from '../../../themes/styles'

const MultipliersPoints = (inputs) => {
  const classes = useStyles()

  const banksActive = getBanksActivePoints([...inputs.cardsWallet, ...inputs.cardsAnalyze])

  return <Box>
    <Typography variant="h5">Point Conversions</Typography>
    <Box>
      <Typography variant="body1" className={classes.spacingHalf}>{questions.pointsValue}</Typography>
      <table>
        {banksActive.map((step, index) => (
          <tr>
            <td><Typography variant="body1">{step.disp}:</Typography></td>
            <td><Typography variant="body1" className={classes.fontBold + " " + classes.spacingLeft}>
              {(inputs.userInput.multipliers.points[step.db]/(inputs.userInput.multipliers.status[step.db] ? inputs.userInput.multipliers.status[step.db] : 1)).toFixed(2)}
            </Typography></td>
          </tr>
        ))}
      </table>
    </Box>
  </Box>
}

export default MultipliersPoints
