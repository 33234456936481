import * as React from 'react'

import {Box} from "@material-ui/core"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import {useStyles} from '../../../themes/styles'

import Inputs from '../resultViews/Inputs'

const DialogConfirm = (props) => {
  const classes = useStyles()

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleConfirm = () => {
    props.handleSubmitConfirm()
  }

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you are ready to submit? You cannot change your inputs after submission.
          </DialogContentText>
          <Box className={classes.spacing}>
            <Inputs {...props}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogConfirm
