import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesTravelMisc = (templateData) => {
  const inputs = [
    {
      db: "travel_vacationRentals",
      disp: "Vacation Rentals",
    },
    {
      db: "travel_timeshares",
      disp: "Timeshares",
    },
    {
      db: "travel_camping",
      disp: "Campgrounds",
    },
    {
      db: "travel_tours",
      disp: "Tours",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesTravelMisc
