import cards from '../constants/cards'

const getCardInfo = (card) => {
  for (let iCard = 0; iCard < cards.length; iCard++) {
    if (card === cards[iCard].db) {
      return cards[iCard]
    }
  }
}

export default getCardInfo
