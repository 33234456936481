import {Typography, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import {useStyles} from '../../themes/styles'

const dispFTF = (rate) => {
  if (rate == null) {
    return "N/A"
  }
  else {
    return `${rate}%`
  }
}

const CardDetails = (card) => {
  const classes = useStyles()

  return <Box className={classes.divider}>
    <Stack direction="row" spacing={1} className={classes.spacing}>
      <Typography variant="h6">Rewards Type:</Typography>
      <Typography variant="h6" className={classes.fontBold}>{card.rewardsCash ? "Cash Back" : "Points/Miles"}</Typography>
    </Stack>
    <Box>
      <Typography variant="h6" >Spending Rewards:</Typography>
      <ul>
        {card.rewardsSpending.map((step, index) => (
          <li key={`spending_${index}`}>{step}</li>
        ))}
      </ul>
    </Box>
    {card.rewardsAdditional.length ?
      <Box className={classes.spacing}>
        <Typography variant="h6" >Additional Rewards:</Typography>
        <ul>
          {card.rewardsAdditional.map((step, index) => (
            <li key={`additional_${index}`}>{step}</li>
          ))}
        </ul>
      </Box>
    :
      null
    }
    <Box>
      <Typography variant="h6" >Fees:</Typography>
      <ul>
        <li>
          <Stack direction="row" spacing={1} className={classes.spacing}>
            <Typography variant="body1">Annual Fee:</Typography>
            <Typography variant="body1" className={classes.fontBold}>{`$${card.fees.annual}`}</Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" spacing={1} className={classes.spacing0}>
            <Typography variant="body1">Foreign Transaction Fee:</Typography>
            <Typography variant="body1" className={classes.fontBold}>{dispFTF(card.fees.foreign)}</Typography>
          </Stack>
        </li>
      </ul>
    </Box>
    <Box className={classes.flexGrow}>
      <Stack direction="row" justifyContent="space-around">
        <Button
          variant="contained"
          color="primary"
        >
          <a href={card.link} target="_blank" rel="noreferrer" className={classes.clickWhite}>
            Apply
          </a>
        </Button>
      </Stack>
    </Box>
  </Box>
}

export default CardDetails
