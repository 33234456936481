import CardsWallet from '../components/pages/inputViews/cardsWallet'
import CardsAnalyze from '../components/pages/inputViews/cardsAnalyze'
import BankStatus from '../components/pages/inputViews/bankStatus'
import Sofi from '../components/pages/inputViews/sofi'
import MultipliersCash from '../components/pages/inputViews/multipliersCash'
import MultipliersPoints from '../components/pages/inputViews/multipliersPoints'
import TravelPreferences from '../components/pages/inputViews/travelPreferences'
import Security from '../components/pages/inputViews/security'
import Lounges from '../components/pages/inputViews/lounges'
import PurchasesTravelPrimary from '../components/pages/inputViews/purchasesInput_travelPrimary'
import PurchasesTravelIncidentals from '../components/pages/inputViews/purchases_travelIncidentals'
import PurchasesTravelMisc from '../components/pages/inputViews/purchasesInput_travelMisc'
import PurchasesEntertainment from '../components/pages/inputViews/purchasesInput_entertainment'
import PurchasesStreaming from '../components/pages/inputViews/purchasesInput_streaming'
import PurchasesDining from '../components/pages/inputViews/purchasesInput_dining'
import PurchasesShoppingFood from '../components/pages/inputViews/purchasesInput_shoppingFood'
import PurchasesShoppingRetail from '../components/pages/inputViews/purchasesInput_shoppingRetail'
import PurchasesTransit from '../components/pages/inputViews/purchasesInput_transit'
import PurchasesOther from '../components/pages/inputViews/purchases_other'

import Recap_CardsWallet from '../components/pages/recapViews/CardsWallet'
import Recap_CardsAnalyze from '../components/pages/recapViews/CardsAnalyze'
import Recap_BankStatus from '../components/pages/recapViews/BankStatus'
import Recap_MultipliersCash from '../components/pages/recapViews/MultipliersCash'
import Recap_MultipliersPoints from '../components/pages/recapViews/MultipliersPoints'
import Recap_Lounges from '../components/pages/recapViews/Lounges'
import Recap_Security from '../components/pages/recapViews/Security'
import Recap_Sofi from '../components/pages/recapViews/Sofi'
import Recap_TravelPreferences from '../components/pages/recapViews/TravelPreferences'
import Recap_PreferredAirline from '../components/pages/recapViews/PreferredAirline'

const inputViews = [
  {
    name: "cardsWallet",
    template: CardsWallet,
    templateRecap: Recap_CardsWallet,
    tier: 1,
  },
  {
    name: "cardsAnalyze",
    template: CardsAnalyze,
    templateRecap: Recap_CardsAnalyze,
    tier: 0,
  },
  {
    name: "bankStatus",
    template: BankStatus,
    templateRecap: Recap_BankStatus,
    hideRecap: false,
    tier: 2
  },
  {
    name: "sofiDirectDeposit",
    template: Sofi,
    templateRecap: Recap_Sofi,
    hideRecap: false,
    tier: 2
  },
  {
    name: "multipliersCash",
    template: MultipliersCash,
    templateRecap: Recap_MultipliersCash,
    tier: 1
  },
  {
    name: "multipliersPoints",
    template: MultipliersPoints,
    templateRecap: Recap_MultipliersPoints,
    tier: 3
  },
  {
    name: "lounges",
    template: Lounges,
    templateRecap: Recap_Lounges,
    tier: 3
  },
  {
    name: "airportSecurity",
    template: Security,
    templateRecap: Recap_Security,
    tier: 2
  },
  {
    name: "travelPreferences",
    template: TravelPreferences,
    templateRecap: Recap_TravelPreferences,
    tier: 0
  },
  {
    name: "purchases_travel_primary",
    template: PurchasesTravelPrimary,
    tier: 0
  },
  {
    name: "purchases_travel_incidentals",
    template: PurchasesTravelIncidentals,
    templateRecap: Recap_PreferredAirline,
    tier: 0
  },
  {
    name: "purchases_travel_misc",
    template: PurchasesTravelMisc,
    tier: 0
  },
  {
    name: "purchases_entertainment",
    template: PurchasesEntertainment,
    tier: 0
  },
  {
    name: "purchases_streaming",
    template: PurchasesStreaming,
    tier: 0
  },
  {
    name: "purchases_dining",
    template: PurchasesDining,
    tier: 0
  },
  {
    name: "purchases_shopping_food",
    template: PurchasesShoppingFood,
    tier: 0
  },
  {
    name: "purchases_shopping_retail",
    template: PurchasesShoppingRetail,
    tier: 0
  },
  {
    name: "purchases_transit",
    template: PurchasesTransit,
    tier: 0
  },
  {
    name: "purchases_misc",
    template: PurchasesOther,
    tier: 0
  },
]

export default inputViews
