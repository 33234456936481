

import {Typography} from "@material-ui/core"
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'

import cards from '../../../constants/cards'
import questions from '../../../constants/questions'
import getInputViewSkips from '../../../fns/getInputViewSkips'
import {useStyles} from '../../../themes/styles'

const CardsWallet = (templateData) => {
  const classes = useStyles()

  const handleChangeCard = (event) => {
    var cardsChecked = templateData.pageState.state.inputs.cardsWallet
    if (event.target.checked) {
      cardsChecked.push(event.target.name)
    }
    else {
      cardsChecked.splice(cardsChecked.indexOf(event.target.name), 1)
    }
    templateData.pageState.state.skipViews = getInputViewSkips(templateData.pageState.state.inputs)
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  return <form>
    <Typography variant="h5">
      {questions.cardsWallet}
      <FormGroup
      className={classes.spacing}
      >
        {cards.map((step, index) => (
          <FormControlLabel key={step.db} control={<Checkbox checked={templateData.pageState.state.inputs.cardsWallet.includes(step.db)} name={step.db} onChange={handleChangeCard}/>} label={step.display} />
        ))}
      </FormGroup>
    </Typography>
  </form>
}

export default CardsWallet
