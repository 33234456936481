import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesTransit = (templateData) => {
  const inputs = [
    {
      db: "gas",
      disp: "Gas",
    },
    {
      db: "gas_evCharging",
      disp: "EV Charging",
    },
    {
      db: "transit",
      disp: "Transit",
    },
    {
      db: "transit_rideshare",
      disp: "Rideshare Services",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesTransit
