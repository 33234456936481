import {Typography} from "@material-ui/core"

const AnnualFees = (props) => {

  return ( <>
    <Typography>
      As any good financial analyst will tell you, it depends on how you use the cards.
      Cards with annual fees tend to have better rewards, and you need to spend enough on the credit card to make it worth it to offset the annual fee.
      When we analyze your spending, we take the annual fee into account.
      We'll give you a straight and clear answer whether it's worth it for you to get any card with an annual fee.
    </Typography>
  </> )
}

export default AnnualFees
