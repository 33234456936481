const getValueSpend = (templateData, domain, field) => {
  const inputAnnual = templateData.pageState.state.inputs.userInput.inputViews.inputAnnual
  const spendingDomainField = templateData.pageState.state.inputs.userInput.spending[domain][field]
  if (typeof(spendingDomainField) == 'string') {
    return ("$" + spendingDomainField)
  }

  if (inputAnnual) {
    return ("$" + Math.round(spendingDomainField))
  }
  else {
    const valRounded = Math.round((spendingDomainField/12)*100)
    return ("$" + (valRounded / 100).toFixed(2))
  }
}

export default getValueSpend
