import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import getCardInfo from '../../../fns/getCardInfo'

const CardsAnalyze = (inputs) => {
  return <Box>
    <Typography variant="h5">Cards to consider:</Typography>
    <Box>
      {inputs.cardsAnalyze.map((step, index) => (
        <Typography variant="body1">{getCardInfo(step).display}</Typography>
      ))}
    </Box>
  </Box>
}

export default CardsAnalyze
