const blurSpend = (templateData, domain, field) => {
  let spending = templateData.pageState.state.inputs.userInput.spending
  const spendingDomainField = Number(spending[domain][field].replaceAll(',',''))
  
  if (isNaN(Number(spendingDomainField))) {
    spending[domain][field] = 0
  }
  else {
    const inputAnnual = templateData.pageState.state.inputs.userInput.inputViews.inputAnnual
    if (inputAnnual) {
      spending[domain][field] = Math.round(Number(spendingDomainField) * 100) / 100
    }
    else {
      spending[domain][field] = (Math.round(Number(spendingDomainField) * 100) / 100) * 12
    }
  }
  templateData.pageState.setState({
    ...templateData.pageState.state,
  })
}

export default blurSpend
