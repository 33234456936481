import React, {useEffect, useState} from 'react'
import AppendHead from 'react-append-head'
import {useDispatch} from "react-redux"

import "react-toastify/dist/ReactToastify.css"
import socketIoClient from 'socket.io-client'

import {urlServer} from "./api"
import {loadUser, loggedout, updateName} from "./store/actions/authActions"

import Views from './Views'

function App() {
  const dispatch = useDispatch()

  const [socketState, setSocket] = useState(null)

  useEffect(() => {
    var socket = socketIoClient(urlServer, {
      reconnectionDelay: 1000,
      reconnection:true,
      reconnectionAttempts: 10,
      transports: ['websocket'],
      agent: false,
      upgrade: false,
    })

    try {
      socket.on('connect', () => {
        dispatch(loadUser(socket))
      })
    } catch (e) {
      console.log("ERROR CONNECTING TO SOCKET")
      console.log(e)
    }
    socket.on('connect_error', (err) => {
      console.log(`Socket connect_erorr due to ${err.message}`)
    })

    socket.on('logout', () => {
      dispatch(loggedout(socket))
    })
    socket.on('user', (updatedUser) => {
      dispatch(updateName(updatedUser.name))
    })

    window.addEventListener("storage", function(event)  {
      if (event.key === "loggedIn") {
        console.log("local storage: loggedIn change")
        dispatch(loadUser(socket))
      }
    })

    setSocket(socket)

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  return (
    <div className="App">
      <AppendHead>
        <style>
          {"@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');"}"
        </style>
      </AppendHead>
      <Views socket={socketState}/>
    </div>
  );
}

export default App
