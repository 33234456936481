

const initialState = {
  connected: false,
  loading: true,
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "APP_CONNECTED":
      return {
        ...state,
        connected: true,
        loading: false,
      }
    case "LOADING_TOGGLE":
      return {
        ...state,
        loading: action.isLoading,
      }
    default:
      return state
  }
}

export default appReducer
