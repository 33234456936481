import React, {useEffect, useState} from 'react'

import {Typography, Box} from "@material-ui/core"
import AccordionDetails from '@mui/material/AccordionDetails'

import Faq_AnalysisType from './faqViews/analysisType'
import Faq_AnnualFees from './faqViews/annualFees'
import Faq_BradnedCards from './faqViews/brandedCards'
import Faq_CreditScore from './faqViews/creditScore'
import Faq_RewardsType from './faqViews/rewardsType'
import Accordion from '../styled/Accordion'
import AccordionSummary from '../styled/AccordionSummary'
import {useStyles} from '../../themes/styles'

const faqs = [
  {
    name: "analysisType",
    question: "What's the difference between the Next-Card Analysis and the Full-Deck Analysis?",
    response: Faq_AnalysisType,
  },
  {
    name: "annualFees",
    question: "Are cards with annual fees worth the cost?",
    response: Faq_AnnualFees,
  },
  {
    name: "rewardsType",
    question: "Should I get credit cards with cash rewards or points/miles?",
    response: Faq_RewardsType,
  },
  {
    name: "brandedCards",
    question: "Do you ever recommended branded cards?",
    response: Faq_BradnedCards,
  },
  {
    name: "creditScore",
    question: "Is it okay if I don't have great credit?",
    response: Faq_CreditScore,
  },
]

const Faq = (props) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return ( <>
    <Typography variant="h4" sx={{}}>Frequently Asked Questions</Typography>
    <div className={classes.spacing}>
      {faqs.map((step, index) => (
        <Accordion expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary aria-controls={`panel-content_${step.name}`} id={`panel-header_${step.name}`}>
            <Typography>{step.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
            component={step.response}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  </> )
}

export default Faq
