import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesShoppingRetail = (templateData) => {
  const inputs = [
    {
      db: "onlineShopping",
      disp: "Online Shopping",
    },
    {
      db: "departmentStores",
      disp: "Department Stores",
    },
    {
      db: "drugPharmacies",
      disp: "Drug Stores & Pharmacies",
    },
    {
      db: "home_furnishing",
      disp: "Home Furnishing Stores",
    },
    {
      db: "home_improvement",
      disp: "Home Improvement Stores",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesShoppingRetail
