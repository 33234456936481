import * as React from 'react'
import {useEffect} from 'react'

import {Typography, TextField, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import questions from '../../../constants/questions'
import blurSpend from '../../../fns/blurSpend'
import focusSpend from '../../../fns/focusSpend'
import getValueSpend from '../../../fns/getValueSpend'
import handleChangeAnnual from '../../../fns/handleChangeAnnual'
import handleChangeSpend from '../../../fns/handleChangeSpend'
import {useStyles} from '../../../themes/styles'

import DoubleSwitch from '../../common/DoubleSwitch'

const PurchasesTravelPrimary = (templateData) => {
  const classes = useStyles()

  const handleAirline = (preferredAirline) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.preferredAirline = preferredAirline
    templateData.pageState.setState({
      ...templateData.pageState.state,
      disableNext: false,
    })
  }

  useEffect(() => {
    if (templateData.pageState.state.inputs.userInput.inputViews.preferredAirline == null) {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: true,
      })
    }
    else {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <form>
    <Typography variant="h5">
      {questions.preferredAirline}
      <FormGroup
      className={classes.spacing}
      >
        <Box
        component="span"
        className={classes.spreadBoxCenter}
        >
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.preferredAirline === true ? "default" : "secondary"}
            onClick={() => handleAirline(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.preferredAirline === false ? "default" : "primary"}
            onClick={() => handleAirline(true)}
          >
            Yes
          </Button>
        </Box>
        {templateData.pageState.state.inputs.userInput.inputViews.preferredAirline == null ?
          <></>
        :
          <Box className={classes.spacing}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography onClick={event => handleChangeAnnual(templateData, false)} className={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? classes.clickGrey : classes.clickBlack}>Input monthly spending</Typography>
              <DoubleSwitch onChange={event => handleChangeAnnual(templateData, event.target.checked)} checked={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual}/>
              <Typography onClick={event => handleChangeAnnual(templateData, true)} className={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? classes.clickBlack : classes.clickGrey}>Input annual spending</Typography>
            </Stack>
            <Typography variant="h5" className={classes.spacing}>
              {questions.spending} {templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? "year" : "month"} {questions.spending_airlineIncidentals}
            </Typography>
            {templateData.pageState.state.inputs.userInput.inputViews.preferredAirline === true ?
              <FormGroup
              >
                <Box className={classes.spacing}>
                  <Typography variant="h6" className={classes.spacing}>Primary Domestic Airline:</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                      className={classes.spacingHalf}
                      id={"domestic__travel_airlineIncidentalsPrimary"}
                      variant="outlined"
                      value = {getValueSpend(templateData, "domestic", "travel_airlineIncidentalsPrimary")}
                      onFocus = {(event => focusSpend(templateData, "domestic", "travel_airlineIncidentalsPrimary"))}
                      onChange = {(event => handleChangeSpend(templateData, "domestic", "travel_airlineIncidentalsPrimary", event.target.value))}
                      onBlur = {(event => blurSpend(templateData, "domestic", "travel_airlineIncidentalsPrimary"))}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </FormGroup>
            :
              <></>
            }
            <FormGroup
            className={classes.spacing}
            >
              <Box className={classes.spacing}>
                {templateData.pageState.state.inputs.userInput.inputViews.preferredAirline === true ?
                  <Typography variant="h6">All Other Airlines:</Typography>
                :
                  <Typography variant="h6"></Typography>
                }
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                    className={classes.spacingHalf}
                    id={"domestic__travel_airlineIncidentalsOther"}
                    label={templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ? "Domestic Airlines" : ""}
                    variant="outlined"
                    value = {getValueSpend(templateData, "domestic", "travel_airlineIncidentalsOther")}
                    onFocus = {(event => focusSpend(templateData, "domestic", "travel_airlineIncidentalsOther"))}
                    onChange = {(event => handleChangeSpend(templateData, "domestic", "travel_airlineIncidentalsOther", event.target.value))}
                    onBlur = {(event => blurSpend(templateData, "domestic", "travel_airlineIncidentalsOther"))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ?
                      <TextField
                      className={classes.spacingHalf}
                      id={"foreign__travel_airlineIncidentalsOther"}
                      label="Foreign Airlines"
                      variant="outlined"
                      value = {getValueSpend(templateData, "foreign", "travel_airlineIncidentalsOther")}
                      onFocus = {(event => focusSpend(templateData, "foreign", "travel_airlineIncidentalsOther"))}
                      onChange = {(event => handleChangeSpend(templateData, "foreign", "travel_airlineIncidentalsOther", event.target.value))}
                      onBlur = {(event => blurSpend(templateData, "foreign", "travel_airlineIncidentalsOther"))}
                      />
                    :
                      <></>
                    }
                  </Grid>
                </Grid>
              </Box>
            </FormGroup>
          </Box>
        }
      </FormGroup>
    </Typography>
  </form>
}

export default PurchasesTravelPrimary
