import axios from "axios"
import {toast} from "react-toastify"

import {urlApi} from "../../api"

import encrypt from '../../fns/encrypt'

export const changeEmail = (change) => {
  change.password = encrypt(change.password)
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/changeEmail`, change, {"withCredentials": true})
    .then(resp => {
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
      toast("Email updated", {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}

export const changeName = (change) => {
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/changeName`, change, {"withCredentials": true})
    .then(resp => {
      dispatch({
        type: "CHANGE_NAME",
        name: resp.data.name,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
      toast("Name updated", {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}

export const changePassword = (change) => {
  change.password = encrypt(change.password)
  change.passwordNew = encrypt(change.passwordNew)
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/changePassword`, change, {"withCredentials": true})
    .then(resp => {
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
      toast("Password updated", {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}

export const loadUser = (socket) => {
  if (localStorage.getItem('loggedIn')) {
    return (dispatch) => {
      axios
      .post(`${urlApi}/user/load`, {idSocket: socket.id}, {"withCredentials": true})
      .then(resp => {
        dispatch({
          type: "USER_LOADED",
          credits: resp.data.credits,
          hasHistory: resp.data.hasHistory,
          name: resp.data.name,
          numPurchases: resp.data.numPurchases,
          _id: resp.data._id,
        })
        dispatch({
          type: "APP_CONNECTED",
        })
      })
      .catch(error => {
        console.log(error.response?.data)
        localStorage.removeItem('loggedIn')
        dispatch({
          type: "APP_CONNECTED",
        })
      })
    }
  }
  else {
    console.log("LOADUSER: localStorage NOT loggedIn")
    return (dispatch) => {
      dispatch({
        type: "APP_CONNECTED",
      })
    }
  }
}

export const login = (creds, socket, recaptchaToken) => {
  creds.password = encrypt(creds.password)
  const reqBody = {
    ...creds,
    idSocket: socket.id,
    recaptchaToken,
  }
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/login`, reqBody, {"withCredentials": true})
    .then(resp => {
      socket.emit('authUser')
      localStorage.setItem('loggedIn', "true")
      dispatch({
        type: "LOGIN",
        credits: resp.data.credits,
        hasHistory: resp.data.hasHistory,
        name: resp.data.name,
        numPurchases: resp.data.numPurchases,
        _id: resp.data._id,
        socket: socket,
      })
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}

export const logout = () => {
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/logout`, {}, {"withCredentials": true})
    .then(resp => {
      dispatch({
        type: "LOGOUT",
      })
    })
    .catch(error => {
      console.log(error.response)
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    })
  }
}

export const loggedout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    })
  }
}

export const pwReset = (reqBody, socket) => {
  reqBody.password = encrypt(reqBody.password)
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/pwReset`, reqBody, {"withCredentials": true})
    .then((res) => {
      socket.emit('authUser')
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
      dispatch({
        type: "PASSWORD_RESET",
        hasHistory: res.data.hasHistory,
        name: res.data.name,
        numPurchases: res.numPurchases,
        _id: res.data._id,
      })
    })
    .catch(error => {
      console.log(error)
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}

export const register = (user, socket, recaptchaToken) => {
  const reqBody = {
    name: user.name,
    email: user.email,
    password: encrypt(user.pw1),
    idSocket: socket.id,
    recaptchaToken,
  }
  return (dispatch) => {
    axios
    .post(`${urlApi}/user/register`, reqBody, {"withCredentials": true})
    .then((res) => {
      socket.emit('authUser')
      dispatch({
        type: "REGISTER",
        credits: 0,
        hasHistory: false,
        name: user.name,
        numPurchases: res.data.numPurchases,
        _id: res.data._id,
      })
    })
    .catch(error => {
      console.log(error.response)
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}

export const updateName = (updatedName) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_NAME",
      name: updatedName,
    })
  }
}
