const initialState = {
  credits: 0,
  hasHistory: null,
  name: null,
  numPurchases: null,
  _id: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
    case "PASSWORD_RESET":
    case "REGISTER":
    case "USER_LOADED":
      window.scrollTo(0,0)

      return {
        ...initialState,
        credits: action.credits,
        hasHistory: action.hasHistory,
        name: action.name,
        numPurchases: action.numPurchases,
        _id: action._id,
      }
    case "LOGOUT":
      localStorage.removeItem('loggedIn')
      return {
        ...initialState,
        name: null,
      }
    case "CHANGE_NAME":
      return {
        ...initialState,
        name: action.name,
      }
    default:
      return state
  }
}

export default authReducer
