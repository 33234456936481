import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const TravelPreferences = (inputs) => {
  const classes = useStyles()

  return <Box>
    <Typography variant="h5">Travel Booking</Typography>
    <Typography variant="h6">{questions.bookDirect_TF}</Typography>
    <table>
      <tr>
        <td><Typography variant="body1">Airfare:</Typography></td>
        <td><Box className={classes.spacingLeft}><YesNo bool={!inputs.userInput.bookDirect.airfare}/></Box></td>
      </tr>
      <tr>
        <td><Typography variant="body1">Hotels:</Typography></td>
        <td><Box className={classes.spacingLeft}><YesNo bool={!inputs.userInput.bookDirect.hotels}/></Box></td>
      </tr>
      <tr>
        <td><Typography variant="body1">Car Rentals:</Typography></td>
        <td><Box className={classes.spacingLeft}><YesNo bool={!inputs.userInput.bookDirect.cars}/></Box></td>
      </tr>
      <tr>
        <td><Typography variant="body1">Cruises:</Typography></td>
        <td><Box className={classes.spacingLeft}><YesNo bool={!inputs.userInput.bookDirect.cruises}/></Box></td>
      </tr>
    </table>
  </Box>
}

export default TravelPreferences
