import * as React from 'react'
import {useEffect} from 'react'

import {Typography, TextField, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

const Lounges = (templateData) => {
  const classes = useStyles()

  const [personal, setPersonal] = React.useState(templateData.pageState.state.inputs.userInput.loungeVisits.personal)
  const [guest, setGuest] = React.useState(templateData.pageState.state.inputs.userInput.loungeVisits.guest)

  const handleLounges = (useLounges) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.lounges = useLounges
    templateData.pageState.setState({
      ...templateData.pageState.state,
      disableNext: false,
    })
  }

  const handleBlur = () => {
    if (personal === "" || isNaN(personal)) {
      setPersonal(0)
      templateData.pageState.state.inputs.userInput.loungeVisits.personal = 0
    }
    else {
      templateData.pageState.state.inputs.userInput.loungeVisits.personal = Number(personal)
    }
    if (guest === "" || isNaN(guest)) {
      setGuest(0)
      templateData.pageState.state.inputs.userInput.loungeVisits.guest = 0
    }
    else {
      templateData.pageState.state.inputs.userInput.loungeVisits.guest = Number(guest)
    }
    if (guest > personal) {
      setGuest(personal)
      templateData.pageState.state.inputs.userInput.loungeVisits.guest = templateData.pageState.state.inputs.userInput.loungeVisits.personal
    }
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const handleChange = (setVal, val) => {
    setVal(val)
  }

  const handleFocus = (setVal, val) => {
    if (val === 0) {
      setVal("")
    }
  }

  useEffect(() => {
    if (templateData.pageState.state.inputs.userInput.inputViews.lounges == null) {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: true,
      })
    }
    else {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <form>
    <Typography variant="h5">
      {questions.lounges}
      <FormGroup
      className={classes.spacing}
      >
        <Box
        component="span"
        className={classes.spreadBoxCenter}
        >
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.lounges === true ? "default" : "secondary"}
            onClick={() => handleLounges(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.lounges === false ? "default" : "primary"}
            onClick={() => handleLounges(true)}
          >
            Yes
          </Button>
        </Box>
        {templateData.pageState.state.inputs.userInput.inputViews.lounges !== true ?
          <></>
        :
        <>
          <Box
          className={classes.spacing}
          >
            <Box>{questions.loungesPersonal}</Box>
            <TextField
            id="personal"
            className={classes.spacing}
            variant="outlined"
            value = {personal}
            onFocus = {(event => handleFocus(setPersonal, personal))}
            onChange = {(event => handleChange(setPersonal, event.target.value))}
            onBlur = {(event => handleBlur())}
            />
          </Box>
          <Box
          className={classes.spacing}
          >
            <Box>{questions.loungesGuest}</Box>
            <TextField
            id="guest"
            className={classes.spacing}
            variant="outlined"
            value={guest}
            onFocus = {(event => handleFocus(setGuest, guest))}
            onChange = {(event => handleChange(setGuest, event.target.value))}
            onBlur = {(event => handleBlur())}
            />
          </Box>
        </>
        }

      </FormGroup>
    </Typography>
  </form>
}

export default Lounges
