import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {useLocation} from "react-router-dom"

import {Typography, TextField, Button} from "@material-ui/core"

import inputLimits from '../../constants/inputLimits'
import PageAfterLogin from '../../constants/pageAfterLogin'
import {loadingToggle} from "../../store/actions/appActions"
import {pwReset} from '../../store/actions/authActions'
import {useStyles} from '../../themes/styles'

const useQuery = () => {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const PwReset = (props) => {
  const dispatch = useDispatch()
  const query = useQuery()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const fields = {
    key: (query.get("key") ? query.get("key") : ""),
    pw1: "",
    pw2: "",
  }

  const [inputs, setInputs] = useState(fields)
  const [errors, setErrors] = useState(fields)
  const [key, setKey] = useState(query.get("key"))

  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    inputs[typeUser] = val
    setInputs({...inputs})
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    const errorKey = await validateKey()
    const errorPw1 = await validatePw1()
    const errorPw2 = await validatePw2()
    if (errorKey || errorPw1 || errorPw2) {
      dispatch(loadingToggle(false))
    }
    else {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
          const keySplit = inputs.key.toLowerCase().split("-")
          const reqBody = {
            key1: keySplit[0],
            key2: keySplit[1],
            password: inputs.pw1,
            recaptchaToken: token,
          }
          dispatch(pwReset(reqBody, props.socket))
          setInputs({
            ...inputs,
            pw1: "",
            pw2: "",
          })
        })
      })
    }
  }

  const validateKey = () => {
    var error = ""
    if (inputs.key?.length) {
      error = "Invalid key"
      const keySplit = inputs.key.split("-")
      if (keySplit.length === 2) {
        if (keySplit[0].length === inputLimits.key1 && keySplit[1].length === inputLimits.key2) {
          error = ""
        }
      }
    }
    setErrors({
      ...errors,
      key: error,
    })
    return error
  }

  const validatePw1 = async () => {
    var error = ""
    if (!inputs.pw1.length) {
      error = "Please enter a password"
    }
    else if (inputs.pw1.length < inputLimits.password.min) {
      error = ("Please enter a password at least " + inputLimits.password.min + " characters long")
    }
    if (inputs.pw2.length) {
      validatePw2()
    }
    setErrors({
      ...errors,
      pw1: error,
    })
    return error
  }

  const validatePw2 = () => {
    var error = ""
    if (inputs.pw1) {
      if (!inputs.pw2.length) {
        error = "Please re-enter your password"
      }
      else if (inputs.pw2 !== inputs.pw1) {
        error = "Passwords do not match"
      }
    }
    setErrors({
      ...errors,
      pw2: error,
    })
    return error
  }

  useEffect(() => {
    async function validateKeyAndSetError() {
      const errorKey = await validateKey(key)
      if (errorKey) {
        setErrors({
          ...errors,
          key: errorKey,
        })
        setKey("")
      }
    }
    validateKeyAndSetError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (auth.name) {
    return <PageAfterLogin/>
  }

  return (
    <form
    className={classes.shadowBox}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
    <Typography variant="h4" sx={{}}>Reset Password</Typography>
    {key ?
        null
      :
        <TextField
          id="enter-key"
          className={classes.spacing}
          label="Enter Key"
          variant="outlined"
          fullWidth
          value={inputs.key}
          onChange = {(event) => setInputs({...inputs, key: event.target.value})}
          error={Boolean(errors.key)}
          helperText={errors.key}
          onBlur={(event) => validateKey(event.target.value)}
        />
      }
      <TextField
        id="enter-password"
        className={classes.spacing}
        type="password"
        label="Enter Password"
        variant="outlined"
        fullWidth
        value={inputs.pw1}
        onChange = {(event) => changeVal(event, "password", "pw1")}
        error={Boolean(errors.pw1)}
        helperText={errors.pw1}
        onBlur={() => validatePw1()}
      />
      <TextField
        id="reenter-password"
        className={classes.spacing}
        type="password"
        label="Re-enter Password"
        variant="outlined"
        fullWidth
        value={inputs.pw2}
        onChange = {(event) => changeVal(event, "password", "pw2")}
        error={Boolean(errors.pw2)}
        helperText={errors.pw2}
        onBlur={() => validatePw2()}
      />
      <Button
        className={classes.spacing}
        variant="contained"
        color="primary"
        type="submit"
      >
        Submit
      </Button>
    </form>
  )
}

export default PwReset