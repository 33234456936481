import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import inputViews from '../../../constants/inputViews'
import findIndexMax from '../../../fns/findIndexMax'
import includeBank from '../../../fns/includeBank'
import {useStyles} from '../../../themes/styles'

import RecapSpending from '../recapViews/Spending'

var indexPurchases = 0
for (let iInput = 0; iInput < inputViews.length; iInput++) {
  if (inputViews[iInput].name.split('_')[0] === 'purchases') {
    indexPurchases = iInput
    break
  }
}

const Inputs = (props) => {
  const classes = useStyles()

  const handleModify = (index) => {
    props.setState({
      ...props.state,
      indexInputView: index,
      progress: (index/findIndexMax(props.inputs.tier.level))*100,
    })
    props.setOpen(false)
  }

  const includeBoa = includeBank(props.inputs.cardsWallet, "boa") || includeBank(props.inputs.cardsAnalyze, "boa")
  const includeSofi = includeBank(props.inputs.cardsWallet, "sofi") || includeBank(props.inputs.cardsAnalyze, "sofi")

  for (let i = 0; i < inputViews.length; ++i) {
    if (inputViews[i].name === "bankStatus") {
      inputViews[i].hideRecap = !includeBoa
    }
    else if (inputViews[i].name === "sofiDirectDeposit") {
      inputViews[i].hideRecap = !includeSofi
    }
  }

  return ( <Box className={classes.shadowBox}>
    <Box className={classes.spacingMinus}/>
    {inputViews.map((step, index) => (
      <Box key={"input_" + index} className={classes.spacing}>
        {((props.inputs.tier.level >= step.tier) && step.templateRecap && !step.hideRecap) ?
          <>
            <Box
              component={step.templateRecap}
              {...props.inputs}
            />
            {props.setState ?
              <Box sx={{ textAlign: 'right'}} className={classes.linkStyle}>
                <Button onClick={() => handleModify(index)}>Modify</Button>
              </Box>
            :
              null
            }
          </>
        :
          null
        }
      </Box>
    ))}
    <RecapSpending {...props.inputs}/>
    {props.setState ?
      <Box sx={{ textAlign: 'right'}} className={classes.linkStyle}>
        <Button onClick={() => handleModify(indexPurchases)}>Modify</Button>
      </Box>
    :
      null
    }
  </Box> )
}

export default Inputs
