import {Divider} from '@material-ui/core'
import {styled} from '@mui/material/styles'

const DividerMarginMulti = styled(Divider)(() => ({
  'margin-top': '10px',
  'margin-bottom': '60px',
  'marginLeft': '10px',
  'marginRight': '10px',
}))

export default DividerMarginMulti
