import {Typography} from "@material-ui/core"

const RewardsType = (props) => {

  return ( <>
    <Typography>
      Travel credit cards that offer rewards with points/miles can offer some amazing benefits.
      However, to maximize these benefits, it often requires transfering rewards to partner airlines and then booking airfare with those points.
      Some people consider these extra steps to not be worth the hassle.
      <br/>
      <br/>
      If you'd rather avoid this, we recommend sticking to credit cards with cash rewards.
    </Typography>
  </> )
}

export default RewardsType
