const handleChangeSpend = (templateData, domain, field, val) => {
  let spending = templateData.pageState.state.inputs.userInput.spending
  if (val[0] !== '$') {
    spending[domain][field] = ''
  }
  else {
    val = val.replaceAll('$','')
    if (val[0] === '.') {
      val = '0' + val
    }
    spending[domain][field] = val
  }
  templateData.pageState.setState({
    ...templateData.pageState.state,
  })
}

export default handleChangeSpend
