import {Typography, TextField} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import {useStyles} from '../../themes/styles'
import questions from '../../constants/questions'
import blurSpend from '../../fns/blurSpend'
import focusSpend from '../../fns/focusSpend'
import getValueSpend from '../../fns/getValueSpend'
import handleChangeAnnual from '../../fns/handleChangeAnnual'
import handleChangeSpend from '../../fns/handleChangeSpend'

import DoubleSwitch from './DoubleSwitch'

const PurchaseInput = (templateData) => {
  const classes = useStyles()

  return <form>
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography onClick={event => handleChangeAnnual(templateData, false)} className={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? classes.clickGrey : classes.clickBlack}>Input monthly spending</Typography>
      <DoubleSwitch onChange={event => handleChangeAnnual(templateData, event.target.checked)} checked={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual}/>
      <Typography onClick={event => handleChangeAnnual(templateData, true)} className={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? classes.clickBlack : classes.clickGrey}>Input annual spending</Typography>
    </Stack>
    <Typography variant="h5">
      {questions.spending} {templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? "year" : "month"} {questions.spending_standard}
      <FormGroup
      className={classes.spacing}
      >
        {templateData.inputs.map((step, index) => (
          <Box key={step.db} className={classes.spacing}>
            <Typography variant="h6">{step.disp}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                className={classes.spacingHalf}
                id={"domestic__" + step.db}
                label={templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ? "Domestic Spending" : ""}
                variant="outlined"
                value = {getValueSpend(templateData, "domestic", step.db)}
                onFocus = {(event => focusSpend(templateData, "domestic", step.db))}
                onChange = {(event => handleChangeSpend(templateData, "domestic", step.db, event.target.value))}
                onBlur = {(event => blurSpend(templateData, "domestic", step.db))}
                />
              </Grid>
              <Grid item xs={6}>
                {templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ?
                  <TextField
                  className={classes.spacingHalf}
                  id={"foreign__" + step.db}
                  label="Foreign Spending"
                  variant="outlined"
                  value = {getValueSpend(templateData, "foreign", step.db)}
                  onFocus = {(event => focusSpend(templateData, "foreign", step.db))}
                  onChange = {(event => handleChangeSpend(templateData, "foreign", step.db, event.target.value))}
                  onBlur = {(event => blurSpend(templateData, "foreign", step.db))}
                  />
                :
                  <></>
                }
              </Grid>
            </Grid>
          </Box>
        ))}
      </FormGroup>
    </Typography>
  </form>
}

export default PurchaseInput
