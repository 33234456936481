import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import {useStyles} from '../../themes/styles'

const YesNo = (props) => {
  const classes = useStyles()

  return <Box>
    {props.bool ?
      <Typography variant="body1" className={classes.fontBold + " " + classes.textPos}>YES</Typography>
    :
      <Typography variant="body1" className={classes.fontBold + " " + classes.textNeg}>NO</Typography>
    }
  </Box>
}

export default YesNo
