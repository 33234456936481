import * as React from 'react'
import {useDispatch} from "react-redux"

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import axios from "axios"
import {toast} from "react-toastify"

import {urlApi} from "../../../api"
import {loadingToggle} from "../../../store/actions/appActions"

const DialogReset = (props) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleConfirm = () => {
    dispatch(loadingToggle(true))
    axios
    .post(`${urlApi}/analysis/reset`, {idAnalysis: props.open}, {"withCredentials": true})
    .then(resp => {
      props.setOpen(false)
      dispatch(loadingToggle(false))
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }

  return (
    <div>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>Reset Inputs</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset your inputs? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogReset