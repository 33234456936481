import {createTheme} from '@mui/material/styles'

import colors from './colors'

export default createTheme({
  palette: {
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
  },
})
