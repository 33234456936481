import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"

import axios from "axios"
import {toast} from "react-toastify"

import {Typography, TextField, Button, Box} from "@material-ui/core"

import inputLimits from '../../constants/inputLimits'
import {urlApi} from "../../api"
import {loadingToggle} from "../../store/actions/appActions"
import {useStyles} from '../../themes/styles'

const Contact = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [message, setMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    setMessage(val)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
        let request = {
          message,
          recaptchaToken: token,
        }
        axios
        .post(`${urlApi}/user/contact`, request, {"withCredentials": true})
        .then((res) => {
          setSubmitted(true)
          dispatch(loadingToggle(false))

        })
        .catch(error => {
          console.log(error.response)
          toast.error(error.response?.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
          dispatch({
            type: "LOADING_TOGGLE",
            isLoading: false,
          })
        })
      })
    })
  }

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  if (!auth.name) {
    return (
      <Typography variant="h5">
        You must <Link to="/login">log in</Link> or <Link to="/register">create a free account</Link> to send a message.
    </Typography>
    )
  }

  if (submitted) {
    return <Typography variant="h6" className={classes.shadowBox}>
      Thanks for contacting us. We will get back to you as quickly as we can. Please keep an eye out for an email from us.
    </Typography>
  }

  return ( <>
    <form
    className={classes.shadowBox}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
      <Typography variant="h4" sx={{}}>Contact Us</Typography>
      <Box className={classes.spacing}>
        <TextField
          id="enter-message"
          label="Message"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange = {(event) => {
            changeVal(event, "message")
          }}
        />
        <Typography variant="body1">{message.length} / {inputLimits.message.max}</Typography>
        <Button
          className={classes.spacing}
          variant="contained"
          color="primary"
          type="submit"
          disabled={!Boolean(message.length)}
        >
          Submit
        </Button>
      </Box>
    </form>
  </> )
}

export default Contact