import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const Lounges = (inputs) => {
  const classes = useStyles()

  return <Box>
    <Typography variant="h5">Premium Airport Lounges</Typography>
    <Box className={classes.spacingHalf}>
      <Typography variant="body1">{questions.lounges}</Typography>
      <YesNo bool={inputs.userInput.inputViews.lounges}/>
    </Box>
    {inputs.userInput.inputViews.lounges ?
      <>
        <Box className={classes.spacingHalf}>
          <Typography variant="body1">{questions.loungesPersonal}</Typography>
          <Typography variant="body1" className={classes.fontBold}>{inputs.userInput.loungeVisits.personal}</Typography>
        </Box>
        <Box className={classes.spacingHalf}>
          <Typography variant="body1">{questions.loungesGuest}</Typography>
          <Typography variant="body1" className={classes.fontBold}>{inputs.userInput.loungeVisits.guest}</Typography>
        </Box>
      </>
    :
      null
    }
  </Box>
}

export default Lounges
