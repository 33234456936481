import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

import DoubleSwitch from '../../common/DoubleSwitch'

const TravelPreferences = (templateData) => {
  const classes = useStyles()

  const travelCategories = [
    {
      db: "airfare",
      display: "Airfare",
    },
    {
      db: "hotels",
      display: "Hotels",
    },
    {
      db: "cars",
      display: "Car Rentals",
    },
    {
      db: "cruises",
      display: "Cruises",
    },
  ]

  const handleChange = (field, val) => {
    templateData.pageState.state.inputs.userInput.bookDirect[field] = val
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  return <form>
    <Typography variant="h5">
      {questions.bookDirect}
    </Typography>
    {travelCategories.map((step, index) => (
      <Box key={step.db} className={classes.spacing}>
        <Typography variant="h6">{step.display} </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography sx={{":hover":{cursor:"pointer"}}} onClick={event => handleChange(step.db, false)} className={templateData.pageState.state.inputs.userInput.bookDirect[step.db] ? classes.clickGrey : classes.clickBlack}>Third-party</Typography>
          <DoubleSwitch onChange={event => handleChange(step.db, event.target.checked)} checked={templateData.pageState.state.inputs.userInput.bookDirect[step.db]}/>
          <Typography cursor="pointer" onClick={event => handleChange(step.db, true)} className={templateData.pageState.state.inputs.userInput.bookDirect[step.db] ? classes.clickBlack : classes.clickGrey}>Book Directly</Typography>
      </Stack>
      </Box>
    ))}
  </form>
}

export default TravelPreferences
