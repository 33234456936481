import axios from "axios"
import {toast} from "react-toastify"

import {urlApi} from "../../api"

export const saveProgress = (inputs, idSocket) => {
  return (dispatch) => {
    axios
    .post(`${urlApi}/analysis/saveProgress`, {inputs, idSocket}, {"withCredentials": true})
    .then(resp => {
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
      toast("PROGRESS SAVED", {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    })
    .catch(error => {
      console.log(error.response?.data)
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }
}
