import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesStreaming = (templateData) => {
  const inputs = [
    {
      db: "streaming_video",
      disp: "Streaming Video",
    },
    {
      db: "streaming_music",
      disp: "Streaming Music",
    },
    {
      db: "streaming_audiobook",
      disp: "Streaming Audiobooks",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesStreaming
