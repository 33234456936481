import * as React from 'react'
import {useSelector} from "react-redux"

import {Backdrop, CircularProgress} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  backdrop: {
    zIndex: 9999,
  },
})

const Loading = () => {
  const loading = useSelector(state => state.app.loading)
  const classes = useStyles()

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Loading
