import {useSelector} from "react-redux"
import {Redirect} from "react-router-dom"

import pageAfterLogin_str from './pageAfterLogin_str'

const PageAfterLogin = () => {
  const auth = useSelector(state => state.auth)
  return <Redirect to={pageAfterLogin_str(auth)}/>
}

export default PageAfterLogin
