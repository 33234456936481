import React from 'react'

import IconExpandMore from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import categories from '../../../constants/categories'
import {useStyles} from '../../../themes/styles'

import CardDetails from '../../common/cardDetails'

import Accordion from '../../styled/Accordion'
import AccordionSummary from '../../styled/AccordionSummary'

const fontSizes = {
  h1: 24,
  h2: 22,
  h3: 20,
  b1: 18,
  b2: 16,
}

const calcDelta = (resultConsolidated, index) => {
  if (resultConsolidated[index].cardsResult.length > resultConsolidated[index-1].cardsResult.length) {
    return resultConsolidated[index].total - resultConsolidated[index-1].total
  }
  else {
    return resultConsolidated[index].total - resultConsolidated[0].total
  }
}

const calcIndex = (iParent, iChild, iGrandchild) => {
  return ((10000 * (iParent + 1)) + (100 * (iChild + 1)) + (iGrandchild + 1))
}

const displayCash = (value, showPlus) => {
  if (value < 0) {
    return ("-$" + (-value))
  }
  else {
    if (showPlus) {
      return ("+$" + value)
    }
    else {
      return ("$" + value)
    }
  }
}

const displayCategory = (category, showDomestic) => {
  const split = category.split("__")
  if (split[0] === 'domestic') {
    if (showDomestic) {
      return (categories[split[1]] + " (Domestic)")
    }
    else {
      return categories[split[1]]
    }
  }
  else if (split[0] === 'foreign') {
    return (categories[split[1]] + " (Foreign)")
  }
  else {
    return categories[category]
  }
}

const displayMultiplier = (card) => {
  if (card.db.split("_")[0] === 'boa') {
    return "Bank Status Bonus"
  }
  if (card.tier === 3) {
    return "Conversion Value"
  }
  return "Gift Card Conversion Bonus"
}

const displayPoints = (card, value) => {
  if (card.tier === 3) {
    return ((value * 100) + " points")
  }
  else {
    return ""
  }
}

const displayVal = (card, value) => {
  if (displayPoints(card, value)) {
    return displayPoints(card, value)
  }
  else {
    return displayCash(value)
  }
}

const getClass = (classes, total, displayPoints) => {
  if (displayPoints) {
    return ""
  }
  if (total) {
    if (total > 0) {
      return classes.textPos
    }
    else {
      return classes.textNeg
    }
  }
  else {
    return ""
  }
}

const getFeeTotal = (annual, foreign) => {
  return -(annual + foreign)
}

const showAdditionalValue = (additionalValue) => {
  return (additionalValue.lounge + additionalValue.clear + additionalValue.tsa + additionalValue.redemption)
}

const showConversion = (card, rewards) => {
  if ((card.tier === 3) || (rewards.conversion)) {
    var value = rewards.conversion
    if (card.tier === 3) {
      value = rewards.base + rewards.bonus + rewards.conversion
    }
    return ("$" + value)
  }
  else {
    return ""
  }
}

const showDelta = (resultConsolidated, index) => {
  return (resultConsolidated[index].cardsResult.length > resultConsolidated[0].cardsResult.length)
}

const showDetailedSpending = (spending) => {
  return spending.domestic.details.totals.length + spending.foreign.details.totals.length
}

const strBonus = (card) => {
  const bank = card.db.split("_")[0]
  if (bank === "boa") {
    return "Preferred Rewards Bonus"
  }
  else if (bank === "citi") {
    return "Redemption Bonus"
  }
  else {
    return "Additional Bonus"
  }
}

const SummaryLevelTwoPlus = (templateData) => {
  const classes = useStyles()

  const index = templateData.index

  let expandedChildren = templateData.templateState.expandedChildren
  let setExpandedChildren = templateData.templateState.setExpandedChildren
  let expandedGrandchild = templateData.templateState.expandedGrandchild
  let setExpandedGrandchild = templateData.templateState.setExpandedGrandchild

  const handleChangeChild = (panel) => (event, isExpandedChild) => {
    setExpandedGrandchild(false)
    setExpandedChildren(isExpandedChild ? panel : false)
  }

  const handleChangeGrandchild = (panel) => (event, isExpandedGrandchild) => {
    setExpandedGrandchild(isExpandedGrandchild ? panel : false)
  }

  return <div>
    {templateData.summary.resultConsolidated[index].cardsResult.map((stepChild, indexChild) => (
      <Accordion expanded={expandedChildren === calcIndex(index, indexChild, -1)} onChange={handleChangeChild(calcIndex(index, indexChild, -1))} key={index + "_" + indexChild}>
        <AccordionSummary
          expandIcon={<IconExpandMore />}
          aria-controls={("panelResult" + index + "_" + indexChild + "-content")}
          id={("panelResult" + index + "_" + indexChild + "-header")}
          classes={{content: classes.spreadBoxFull}}
        >
          <Stack className={classes.flexGrow}>
            <Stack direction="row" justifyContent="space-between" className={classes.flexGrow}>
              <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h2 }}>
                {stepChild.card.display}
              </Typography>
              <Typography variant="h6" className={getClass(classes, stepChild.total)} sx={{fontSize: fontSizes.h2, fontWeight: 'bold'}}>
                {displayCash(stepChild.total)}
              </Typography>
            </Stack>
            {stepChild.config ?
              <Typography variant="h6" sx={{ width: '100%', flexShrink: 0, fontSize: fontSizes.b1 }} className={classes.fontItalic}>
                Optimum Spending Category:{" " + stepChild.config}
              </Typography>
            :
              <></>
            }
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 6)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 6))} key={index + "_" + indexChild + "_details"}>
              <AccordionSummary
                expandIcon={<IconExpandMore />}
                aria-controls={("panelResult" + index + "_" + indexChild + "-details-content")}
                id={("panelResult" + index + "_" + indexChild + "_details-header")}
                classes={{content: classes.spreadBoxFull}}
              >
                <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                  Card Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CardDetails {...stepChild.card}/>
              </AccordionDetails>
            </Accordion>
            {stepChild.rebates.total ?
              <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 0)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 0))} key={index + "_" + indexChild + "_rebates"}>
                <AccordionSummary
                  expandIcon={<IconExpandMore />}
                  aria-controls={("panelResult" + index + "_" + indexChild + "-rebates-content")}
                  id={("panelResult" + index + "_" + indexChild + "_rebates-header")}
                  classes={{content: classes.spreadBoxFull}}
                >
                  <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                    Rebates
                  </Typography>
                  <Typography variant="h6" className={getClass(classes, stepChild.rebates.total)} sx={{fontSize: fontSizes.h3, fontWeight: 'bold'}}>
                    {displayCash(stepChild.rebates.total)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {stepChild.rebates.itemized.map((stepRebate, indexRebate) => (
                    <>
                      <Box
                      component="span"
                      className={classes.spreadBoxFull + " " + classes.spacing}
                      >
                        <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{displayCategory(stepRebate.category, templateData.pageState.state.inputs.userInput.inputViews.travelInternationally)}</Typography>
                        <Typography variant="body1" className={getClass(classes, stepRebate.value)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                          {displayCash(stepRebate.value)}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{fontSize: fontSizes.b2}} className={classes.fontItalic}>{stepRebate.note}</Typography>
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
            :
              <></>
            }
          </div>
          <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 1)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 1))} key={index + "_" + indexChild + "_domestic"}>
            <AccordionSummary
              expandIcon={<IconExpandMore />}
              aria-controls={("panelResult" + index + "_" + indexChild + "-domestic-content")}
              id={("panelResult" + index + "_" + indexChild + "_domestic-header")}
              classes={{content: classes.spreadBoxFull}}
            >
              <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                {templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ? "Domestic Spending Rewards" : "Everyday Spending Rewards"}
              </Typography>
              <Typography variant="h6" className={getClass(classes, stepChild.spending.domestic.total)} sx={{fontSize: fontSizes.h3, fontWeight: 'bold'}}>
                {displayCash(stepChild.spending.domestic.total)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
              component="span"
              className={classes.spreadBoxFull + " " + classes.spacing}
              >
                <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Base Rewards</Typography>
                <Typography variant="body1" className={getClass(classes, stepChild.spending.domestic.base, displayPoints(stepChild.card))} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                  {displayVal(stepChild.card, stepChild.spending.domestic.base)}
                </Typography>
              </Box>
              {stepChild.spending.domestic.bonus ?
                <Box
                component="span"
                className={classes.spreadBoxFull + " " + classes.spacing}
                >
                  <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{strBonus(stepChild.card)}</Typography>
                  <Typography variant="body1" className={getClass(classes, stepChild.spending.domestic.base)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                    {displayVal(stepChild.card, stepChild.spending.domestic.bonus)}
                  </Typography>
                </Box>
              :
                <></>
              }
              {showConversion(stepChild.card, stepChild.spending.domestic) ?
                <Box
                component="span"
                className={classes.spreadBoxFull + " " + classes.spacing}
                >
                  <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{displayMultiplier(stepChild.card)}</Typography>
                  <Typography variant="body1" className={getClass(classes, stepChild.spending.domestic.base)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                    {showConversion(stepChild.card, stepChild.spending.domestic)}
                  </Typography>
                </Box>
              :
                <></>
              }
            </AccordionDetails>
          </Accordion>
          {stepChild.spending.foreign.totalSpend ?
            <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 2)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 2))} key={index + "_" + indexChild + "_foreign"}>
              <AccordionSummary
                expandIcon={<IconExpandMore />}
                aria-controls={("panelResult" + index + "_" + indexChild + "-foreign-content")}
                id={("panelResult" + index + "_" + indexChild + "_foreign-header")}
                classes={{content: classes.spreadBoxFull}}
              >
                <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                  Foreign Spending Rewards
                </Typography>
                <Typography variant="h6" className={getClass(classes, stepChild.spending.foreign.total)} sx={{fontSize: fontSizes.h3, fontWeight: 'bold'}}>
                  {displayCash(stepChild.spending.foreign.total)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                component="span"
                className={classes.spreadBoxFull + " " + classes.spacing}
                >
                  <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Base Rewards</Typography>
                  <Typography variant="body1" className={getClass(classes, stepChild.spending.foreign.base, displayPoints(stepChild.card))} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                    {displayVal(stepChild.card, stepChild.spending.foreign.total)}
                  </Typography>
                </Box>
                {stepChild.spending.foreign.bonus ?
                  <Box
                  component="span"
                  className={classes.spreadBoxFull + " " + classes.spacing}
                  >
                    <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{strBonus(stepChild.card)}</Typography>
                    <Typography variant="body1" className={getClass(classes, stepChild.spending.foreign.base)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                      {displayVal(stepChild.card, stepChild.spending.foreign.bonus)}
                    </Typography>
                  </Box>
                :
                  <></>
                }
                {showConversion(stepChild.card, stepChild.spending.foreign) ?
                  <Box
                  component="span"
                  className={classes.spreadBoxFull + " " + classes.spacing}
                  >
                    <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{displayMultiplier(stepChild.card)}</Typography>
                    <Typography variant="body1" className={getClass(classes, stepChild.spending.foreign.base)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                      {showConversion(stepChild.card, stepChild.spending.foreign)}
                    </Typography>
                  </Box>
                :
                  <></>
                }
              </AccordionDetails>
            </Accordion>
          :
            <></>
          }
          {showAdditionalValue(stepChild.additionalValue) ?
            <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 3)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 3))} key={index + "_" + indexChild + "_additional"}>
              <AccordionSummary
                expandIcon={<IconExpandMore />}
                aria-controls={("panelResult" + index + "_" + indexChild + "-additional-content")}
                id={("panelResult" + index + "_" + indexChild + "_additional-header")}
                classes={{content: classes.spreadBoxFull}}
              >
                <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                  Additional Value
                </Typography>
                <Typography variant="h6" className={getClass(classes, showAdditionalValue(stepChild.additionalValue))} sx={{fontSize: fontSizes.h3, fontWeight: 'bold'}}>
                  {"(" + displayCash(showAdditionalValue(stepChild.additionalValue)) + ")"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {stepChild.additionalValue.redemption ?
                  <>
                    <Box
                    component="span"
                    className={classes.spreadBoxFull + " " + classes.spacing}
                    >
                      <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Redemption Bonus</Typography>
                      <Typography variant="body1" className={getClass(classes, stepChild.additionalValue.redemption)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                        {"(" + displayCash(stepChild.additionalValue.redemption) + ")"}
                      </Typography>
                    </Box>
                    {stepChild.additionalValue.loungeNote ?
                      <Typography variant="body1" sx={{fontSize: fontSizes.b2}} className={classes.fontItalic}>{stepChild.additionalValue.loungeNote}</Typography>
                    :
                      <></>
                    }
                  </>
                :
                  <></>
                }
                {stepChild.additionalValue.lounge ?
                  <>
                    <Box
                    component="span"
                    className={classes.spreadBoxFull + " " + classes.spacing}
                    >
                      <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Premium Lounge Access</Typography>
                      <Typography variant="body1" className={getClass(classes, stepChild.additionalValue.lounge)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                        {"(" + displayCash(stepChild.additionalValue.lounge) + ")"}
                      </Typography>
                    </Box>
                  </>
                :
                  <></>
                }
                {stepChild.additionalValue.clear ?
                  <Box
                  component="span"
                  className={classes.spreadBoxFull + " " + classes.spacing}
                  >
                    <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Clear Enrollment</Typography>
                    <Typography variant="body1" className={getClass(classes, stepChild.additionalValue.clear)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                      {"(" + displayCash(stepChild.additionalValue.clear) + ")"}
                    </Typography>
                  </Box>
                :
                  <></>
                }
                {stepChild.additionalValue.tsa ?
                  <>
                    <Box
                    component="span"
                    className={classes.spreadBoxFull + " " + classes.spacing}
                    >
                      <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{stepChild.additionalValue.tsa === 20 ? "Global Entry " : "TSA PreCheck " } Enrollment</Typography>
                      <Typography variant="body1" className={getClass(classes, stepChild.additionalValue.tsa)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                        {"(" + displayCash(stepChild.additionalValue.tsa) + ")"}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{fontSize: fontSizes.b2}} className={classes.fontItalic}>{("$" + (stepChild.additionalValue.tsa * 5) + " averaged over five years")}</Typography>
                  </>
                :
                  <></>
                }
              </AccordionDetails>
            </Accordion>
          :
            <></>
          }
          <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 4)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 4))} key={index + "_" + indexChild + "_fees"}>
            <AccordionSummary
              expandIcon={<IconExpandMore />}
              aria-controls={("panelResult" + index + "_" + indexChild + "-fees-content")}
              id={("panelResult" + index + "_" + indexChild + "_fees-header")}
              classes={{content: classes.spreadBoxFull}}
            >
              <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                Fees
              </Typography>
              <Typography variant="h6" className={getClass(classes, getFeeTotal(stepChild.card.fees.annual, stepChild.spending.foreign.fees))} sx={{fontSize: fontSizes.h3, fontWeight: 'bold'}}>
                {displayCash(getFeeTotal(stepChild.card.fees.annual, stepChild.spending.foreign.fees))}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
              component="span"
              className={classes.spreadBoxFull + " " + classes.spacing}
              >
                <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Annual Fee</Typography>
                <Typography variant="body1" className={getClass(-stepChild.card.fees.annual)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                  {displayCash(-stepChild.card.fees.annual)}
                </Typography>
              </Box>
              {stepChild.spending.foreign.totalSpend ?
                <Box
                component="span"
                className={classes.spreadBoxFull + " " + classes.spacing}
                >
                  <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>Foreign Transaction Fees</Typography>
                  <Typography variant="body1" className={getClass(-stepChild.spending.foreign.fees)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                    {displayCash(-stepChild.spending.foreign.fees)}
                  </Typography>
                </Box>
              :
                <></>
              }
            </AccordionDetails>
          </Accordion>
          {showDetailedSpending(stepChild.spending) ?
            <Accordion expanded={expandedGrandchild === calcIndex(index, indexChild, 5)} onChange={handleChangeGrandchild(calcIndex(index, indexChild, 5))} key={index + "_" + indexChild + "_detailed"}>
              <AccordionSummary
                expandIcon={<IconExpandMore />}
                aria-controls={("panelResult" + index + "_" + indexChild + "-detailed-content")}
                id={("panelResult" + index + "_" + indexChild + "_detailed-header")}
                classes={{content: classes.spreadBoxFull}}
              >
                <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h3 }}>
                  Detailed Spending
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: fontSizes.h3}}>{templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ? "Domestic Spending" : ""}</Typography>
                {stepChild.spending.domestic.details.totals.map((stepDomestic, indexDomestic) => (
                  <Box
                  component="span"
                  className={classes.spreadBoxFull + " " + classes.spacing}
                  >
                    <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{displayCategory(stepDomestic.category)}</Typography>
                    <Typography variant="body1" className={getClass(classes, stepDomestic.value)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                      {displayCash(stepDomestic.total)}
                    </Typography>
                  </Box>
                ))}
                {stepChild.spending.foreign.totalSpend ?
                  <div className={classes.spacing}>
                    <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: fontSizes.h3}}>Foreign Spending</Typography>
                    {stepChild.spending.foreign.details.totals.map((stepForeign, indexForeign) => (
                      <Box
                      component="span"
                      className={classes.spreadBoxFull + " " + classes.spacing}
                      >
                        <Typography variant="body1" sx={{fontSize: fontSizes.b1}}>{displayCategory(stepForeign.category)}</Typography>
                        <Typography variant="body1" className={getClass(classes, stepForeign.value)} sx={{fontSize: fontSizes.b1, fontWeight: 'bold'}}>
                          {displayCash(stepForeign.total)}
                        </Typography>
                      </Box>
                    ))}
                  </div>
                :
                  <></>
                }
              </AccordionDetails>
            </Accordion>
          :
            <></>
          }
        </AccordionDetails>
      </Accordion>
    ))}
  </div>
}

const Summary = (templateData) => {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState({
    current: false,
    old: false,
  })
  const [expandedChildren, setExpandedChildren] = React.useState(false)
  const [expandedGrandchild, setExpandedGrandchild] = React.useState(false)
  let templateState = {
    expandedChildren,
    setExpandedChildren,
    expandedGrandchild,
    setExpandedGrandchild,
    expanded
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedGrandchild(false)
    setExpandedChildren(false)
    setExpanded({
      current: isExpanded ? panel : false,
      old: expanded.current
    })
  }

  const skipLevelOne = (inputs) => {
    return (!inputs.cardsWallet.length && !inputs.tier.fullAnalysis)
  }

  return (
    <div>
      {skipLevelOne(templateData.pageState.state.inputs) ?
        <div>
          {templateData.summary.resultConsolidated.map((step, index) => (
            <SummaryLevelTwoPlus pageState={templateData.pageState} templateState={templateState} summary={templateData.summary} index={index}/>
          ))}
        </div>
      :
        <div>
          {templateData.summary.resultConsolidated.map((step, index) => (
            <Accordion expanded={expanded.current === index} onChange={handleChange(index)} key={index}>
              <AccordionSummary
                expandIcon={<IconExpandMore />}
                aria-controls={("panelResult" + index + "-content")}
                id={("panelResult" + index + "-header")}
                classes={{content: classes.spreadBoxFull}}
              >
                <Typography variant="h6" sx={{ width: '73%', flexShrink: 0, fontSize: fontSizes.h1 }}>
                  {step.title}
                </Typography>
                <Stack>
                  <Typography variant="h6" align="right" className={getClass(classes, step.total)} sx={{fontSize: fontSizes.h1, fontWeight: 'bold'}}>
                    {displayCash(step.total)}
                  </Typography>
                  {showDelta(templateData.summary.resultConsolidated, index) ?
                    <Stack direction="row">
                      <Typography variant="h6"sx={{fontSize: fontSizes.h1, fontWeight: 'bold'}} className={classes.fontItalic}>
                        {"("}
                      </Typography>
                      <Typography variant="h6" className={getClass(classes, calcDelta(templateData.summary.resultConsolidated, index)) + " " + classes.fontItalic} sx={{fontSize: fontSizes.h1, fontWeight: 'bold'}}>
                        {displayCash(calcDelta(templateData.summary.resultConsolidated, index), true)}
                      </Typography>
                      <Typography variant="h6"sx={{fontSize: fontSizes.h1, fontWeight: 'bold'}} className={classes.fontItalic}>
                        {")"}
                      </Typography>
                    </Stack>
                  :
                    <></>
                  }
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <SummaryLevelTwoPlus pageState={templateData.pageState} templateState={templateState} summary={templateData.summary} index={index}/>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      }
      <div className={classes.spacing}></div>
      <Typography variant="h5" className={classes.spacing}>
        Notes:
      </Typography>
      <table className={classes.tableAlignTop}>
        <tbody>
          {templateData.summary.notes.map((step, index) => (
            <tr className={classes.spacing}>
              <td className={classes.tableAlignTop + " " + classes.tableColumnSpacing}><Typography variant="h6">{index+1}:</Typography></td>
              <td className={classes.tableAlignTop}><Typography variant="h6">{step}</Typography></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Summary
