import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesShoppingFood = (templateData) => {
  const inputs = [
    {
      db: "grocery",
      disp: "Supermarkets",
    },
    {
      db: "grocery_delivery",
      disp: "Grocery Delivery",
    },
    {
      db: "grocery_wholesale",
      disp: "Wholesale Clubs",
    },
    {
      db: "grocery_niche",
      disp: "Meat Lockers and Dairy Product Stores",
    },
    {
      db: "grocery_convenience",
      disp: "Convenience Stores",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesShoppingFood
