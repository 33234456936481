import * as React from 'react'

import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Slider from '@mui/material/Slider'

import questions from '../../../constants/questions'
import getBanksActivePoints from '../../../fns/getBanksActivePoints'
import {useStyles} from '../../../themes/styles'

const MultipliersPoints = (templateData) => {
  const classes = useStyles()

  const banksActive = getBanksActivePoints([...templateData.pageState.state.inputs.cardsWallet, ...templateData.pageState.state.inputs.cardsAnalyze])

  const calculateValue = (val) => {
    return (val * 0.05) + 0.95
  }

  const dbToSlider = (valDb) => {
    return Math.round((20 * valDb) - 19)
  }

  const handleChangeSlider = (field, val) => {
    const valScaled = calculateValue(val)
    let vals = templateData.pageState.state.inputs.userInput.multipliers.points
    vals[field] = valScaled
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const valueFormat = (val) => {
    return val.toFixed(2)
  }

  const valueLabelFormat = (val) => {
    let scaledVal = (val * 0.05) + 0.95
    return scaledVal.toFixed(2)
  }

  return <form>
    <FormGroup>
      <Box>
        <Box>
          <Typography variant="h5">{questions.pointsValue}</Typography>
          <Typography variant="h6">If you're unsure what to put here, we recommend using the minimum values</Typography>
        </Box>
        <Box
        className={classes.spacing}
        >
          {banksActive.map((step, index) => (
            <Box key={step.db} sx={{ width: 250 }}>
              <Typography id={step.db} gutterBottom>
                {step.disp}: x {valueFormat(templateData.pageState.state.inputs.userInput.multipliers.points[step.db])}
              </Typography>
              <Slider
                value={dbToSlider(templateData.pageState.state.inputs.userInput.multipliers.points[step.db])}
                min={dbToSlider(step.min)}
                step={1}
                max={21}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={event => handleChangeSlider(step.db, event.target.value)}
                valueLabelDisplay="auto"
                aria-labelledby={step.db}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </FormGroup>
  </form>
}

export default MultipliersPoints
