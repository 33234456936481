import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import categories from '../../../constants/categories'
import {useStyles} from '../../../themes/styles'

const Spending = (inputs) => {
  const classes = useStyles()

  const keysCategories = Object.keys(categories)

  return <Box>
  <Typography variant="h5">Average Annual Spending</Typography>
    <Box className={classes.spacingHalf}>
      <table>
        {inputs.userInput.inputViews.travelInternationally ?
          <tr>
            <th></th>
            <th><Box className={classes.spacingLeft}>Domestic</Box></th>
            <th><Box className={classes.spacingLeft}>Foreign</Box></th>
          </tr>
        :
          null
        }
        {keysCategories.map((step, index) => (
          <>
            {
              (
                (
                  Math.round(inputs.userInput.spending.domestic[step])
                  ||
                  Math.round(inputs.userInput.spending.foreign[step])
                )
                &&
                step !== 'other'
              ) ?
              <tr>
                <td><Box className={classes.fontBold0}>{categories[step]}</Box></td>
                <td>
                  {Math.round(inputs.userInput.spending.domestic[step]) ?
                    <Box className={classes.spacingLeft}>{"$" + Math.round(inputs.userInput.spending.domestic[step])}</Box>
                  :
                    null
                }
                </td>
                <td>
                  {(inputs.userInput.inputViews.travelInternationally && Math.round(inputs.userInput.spending.foreign[step])) ?
                    <Box className={classes.spacingLeft}>{"$" + Math.round(inputs.userInput.spending.foreign[step])}</Box>
                  :
                    null
                }
                </td>
              </tr>
            :
              null
            }
          </>
        ))}
        {inputs.userInput.spending.otherStr.map((step, index) => (
          <>
            {
              (
                Math.round(inputs.userInput.spending.otherDomestic[index])
                ||
                Math.round(inputs.userInput.spending.otherForeign[index])
              ) ?
              <tr>
                <td><Box className={classes.textGrey}>{step}</Box></td>
                <td>
                  {Math.round(inputs.userInput.spending.otherDomestic[index]) ?
                    <Box className={classes.spacingLeft}>{"$" + Math.round(inputs.userInput.spending.otherDomestic[index])}</Box>
                  :
                    null
                }
                </td>
                <td>
                  {(inputs.userInput.inputViews.travelInternationally && Math.round(inputs.userInput.spending.otherForeign[index])) ?
                    <Box className={classes.spacingLeft}>{"$" + Math.round(inputs.userInput.spending.otherForeign[index])}</Box>
                  :
                    null
                }
                </td>
              </tr>
            :
              null
            }
          </>
        ))}
      </table>
    </Box>
  </Box>
}

export default Spending
