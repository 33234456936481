import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"

import {Typography, TextField, Button} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles"
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'

import inputLimits from '../../constants/inputLimits'
import PageAfterLogin from '../../constants/pageAfterLogin'
import isEmail from '../../fns/isEmail'
import {loadingToggle} from "../../store/actions/appActions"
import {register} from "../../store/actions/authActions"
import theme from '../../themes/_main'

import DialogPrivacy from './registerViews/dialogPrivacy'
import DialogTerms from './registerViews/dialogTerms'

const useStyles = makeStyles({
  formStyle: {
    margin: "0px auto",
    padding: "30px",
    borderRadius: "9px",
    boxShadow: "0px 0px 12px -3px #000",
  },
  spacing: {
    marginTop: "20px",
  },
})

const Register = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const fields = {
    name: "",
    email: "",
    pw1: "",
    pw2: "",
    terms: false,
  }

  const [errors, setErrors] = useState({...fields})
  const [openPrivacy, setOpenPrivacy] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)
  const [user, setUser] = useState({...fields})

  const changeTerms = async (event) => {
    user.terms = event.target.checked
    setUser({...user})
    validateTerms(true)
  }

  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    user[typeUser] = val
    setUser({...user})
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    const errorName = await validateName(false)
    const errorEmail = await validateEmail(false)
    const errorPw1 = await validatePw1(false)
    const errorPw2 = await validatePw2(false)
    const errorTerms = await validateTerms(false)
    setErrors({
      name: errorName,
      email: errorEmail,
      pw1: errorPw1,
      pw2: errorPw2,
      terms: errorTerms,
    })
    if (errorName || errorEmail || errorPw1 || errorPw2) {
      dispatch(loadingToggle(false))
    }
    else {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
          dispatch(register(user, props.socket, token))
          setUser({
            ...user,
            pw1: "",
            pw2: "",
          })
        })
      })
    }
  }

  const validateEmail = (update) => {
    var error = ""
    if (!user.email.length) {
      error = "Please enter your email"
    }
    else if (!isEmail(user.email)) {
      error = "Please enter a valid email address"
    }
    if (update) {
      setErrors({
        ...errors,
        email: error,
      })
    }
    return error
  }

  const validateName = (update) => {
    var error = ""
    if (!user.name.length) {
      error = "Please enter your name"
    }
    if (update) {
      setErrors({
        ...errors,
        name: error,
      })
    }
    return error
  }

  const validatePw1 = async (update) => {
    var error = ""
    if (!user.pw1.length) {
      error = "Please enter a password"
    }
    else if (user.pw1.length < inputLimits.password.min) {
      error = ("Please enter a password at least " + inputLimits.password.min + " characters long")
    }
    if (user.pw2) {
      await validatePw2(update)
    }
    if (update) {
      setErrors({
        ...errors,
        pw1: error,
      })
    }
    return error
  }

  const validatePw2 = async (update) => {
    var error = ""
    if (user.pw1) {
      if (!user.pw2.length) {
        error = "Please re-enter your password"
      }
      else if (user.pw2 !== user.pw1) {
        error = "Passwords do not match"
      }
    }
    if (update) {
      setErrors({
        ...errors,
        pw2: error,
      })
    }
    return error
  }

  const validateTerms = async (update) => {
    const error = !user.terms
    if (update) {
      setErrors({
        ...errors,
        terms: error,
      })
    }
    return error
  }

  if (auth.name) {
    return <PageAfterLogin/>
  }

  return ( <>
    <DialogTerms open={openTerms} setOpen={setOpenTerms}/>
    <DialogPrivacy open={openPrivacy} setOpen={setOpenPrivacy}/>

    <form
    className={classes.formStyle}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
      <TextField
        id="enter-name"
        className={classes.spacing}
        label="Enter Name"
        variant="outlined"
        fullWidth
        value={user.name}
        onChange = {(event) => changeVal(event, "name")}
        error={Boolean(errors.name)}
        helperText={errors.name}
        onBlur={() => validateName(true)}
      />
      <TextField
        id="enter-email"
        className={classes.spacing}
        label="Enter Email"
        variant="outlined"
        fullWidth
        value={user.email}
        onChange = {(event) => changeVal(event, "email")}
        error={Boolean(errors.email)}
        helperText={errors.email}
        onBlur={(event) => validateEmail(true)}
      />
      <TextField
        id="enter-password"
        className={classes.spacing}
        type="password"
        label="Enter Password"
        variant="outlined"
        fullWidth
        value={user.pw1}
        onChange = {(event) => changeVal(event, "password", "pw1")}
        error={Boolean(errors.pw1)}
        helperText={errors.pw1}
        onBlur={() => validatePw1(true)}
      />
      <TextField
        id="reenter-password"
        className={classes.spacing}
        type="password"
        label="Re-enter Password"
        variant="outlined"
        fullWidth
        value={user.pw2}
        onChange = {(event) => changeVal(event, "password", "pw2")}
        error={Boolean(errors.pw2)}
        helperText={errors.pw2}
        onBlur={() => validatePw2(true)}
      />
      <FormGroup>
        <FormControlLabel
          className={classes.spacing}
          control={
            <Checkbox
            checked={user.terms}
            onChange={(event) => changeTerms(event)}
            sx={{
              '&.Mui-checked': {
                color: theme.palette.primary.main
              }
            }}
            name="terms"
            />
          }
          label={
            <Typography variant="body1">
              I aggree to the {" "}
              <Link to="#terms" onClick={() => setOpenTerms(true)}>Terms of Use</Link>
              {" "} and {" "}
              <Link to="#privacy" onClick={() => setOpenPrivacy(true)}>Privacy Policy</Link>
            </Typography>
          }
        />
      </FormGroup>
      {errors.terms ?
        <FormHelperText error={errors.terms}>You must agree to the Terms of Use and Privacy Policy to register</FormHelperText>
      :
        null
      }
      <Button
        className={classes.spacing}
        variant="contained"
        color="primary"
        type="submit"
      >
        Sign Up
      </Button>
      <Stack direction="row" spacing={1} className={classes.spacing}>
        <Typography variant="h6">Already have an account?</Typography>
        <Typography variant="h6" component={Link} to={"/login"}>Sign in</Typography>
      </Stack>
    </form>
  </> )
}

export default Register