import inputViews from '../constants/inputViews'

const findIndexMax = (tierLevel) => {
  for (let iFindMax = (inputViews.length - 1); iFindMax >= 0; iFindMax--) {
    if (tierLevel >= inputViews[iFindMax].tier) {
      return iFindMax
    }
  }
}

export default findIndexMax
