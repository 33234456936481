import {Typography} from "@material-ui/core"

const AnalysisType = (props) => {

  return ( <>
    <Typography>
      Both analysis types start off with what cards you already have, analyze how you use your credit cards, and make recommendations for you.
      <br/>
      <br/>
      The Next-Card Analysis is the better choise if you're only looking to add one credit card to your deck.
      We'll look at the available cards and let you know how much additional rewards you can expect to receive every year with each option.
      <br/>
      <br/>
      The Full-Deck Analysis is better is you're looking to add more than one card to your current deck.
      Not only do the spending rewards from different credit cards complement each other, but some benefits can be applied to additional cards.
      The Full-Deck Analysis looks at at all the possible new combinations to find which combinations of new cards are the best for you.
      While the Next-Card Analysis may tell you that you can get the most benefit from adding one card, the Full-Deck Analysis goes beyond and might show you the benefit of adding two different cards.
      <br/>
      <br/>
      The good news is that you can't go wrong with either option!
    </Typography>
  </> )
}

export default AnalysisType
