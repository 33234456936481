const categories = {

  travel_hotels: "Hotels",
  travel_carRentals: "Car Rentals",
  travel_cruises: "Cruises",
  travel_airfare: "Airfare",
  travel_airlineIncidentalsPrimary: "Primary Airline Incidental Fees",
  travel_airlineIncidentalsOther: "Additional Airline Incidental Fees",
  travel_travelAgencies: "Travel Agencies",
  travel_vacationRentals: "Vacation Rentals",
  travel_timeshares: "Timeshares",
  travel_camping: "Camping",
  travel_tours: "Tours",
  shared_touristAttractions: "Tourist Attractions",
  shared_amusementParks: "Amusement Parks",
  entertainment_live: "Live Entertainment",
  entertainment_movies: "Movie Theaters",
  fitnessClubs: "Fitness Clubs",
  streaming_video: "Video Streaming",
  streaming_music: "Music Streaming",
  streaming_audiobook: "Audiobook Streaming",
  dining: "Restaurants",
  dining_delivery: "Restaurant Delivery",
  dining_bars: "Bars & Lounges",
  dining_bakeries: "Bakeries",
  grocery: "Supermarkets",
  grocery_delivery: "Grocery Delivery",
  grocery_wholesale: "Wholesale Clubs",
  grocery_niche: "Meat Lockers & Dairy Product Stores",
  grocery_convenience: "Convenience Stores",
  onlineShopping: "Online Shopping",
  departmentStores: "Department Stores",
  drugPharmacies: "Drug Stores & Pharmacies",
  home_furnishing: "Home Furnishing Stores",
  home_improvement: "Home Improvement Stores",
  gas: "Gas",
  gas_evCharging: "EV Charging",
  transit: "Transit",
  transit_rideshare: "Rideshare Services",
  other: "Misc.",
}

export default categories
