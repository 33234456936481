const questions = {
  boaAccount: "Do you currently have a checking or savings account with Bank of America and/or an online brokerage account with Merrill (formerly Merrill Lynch)?",
  boaAccountBoa: "How much combined cash do you currently have in your Bank of America accounts?",
  boaAccountMerrill: "How much combined value do you currently have in your Merrill accounts?",
  boaAccountNew_add: "Would you be interested in adding more to your accounts if it could earn you more credit card rewards?",
  boaAccountNew_new: "Would you be interested in opening a new account if it could earn you more credit card rewards?",
  boaAccountNewBoa_add: "How much combined cash do you have available to add to your Bank of America accounts?",
  boaAccountNewBoa_new: "How much combined cash do you have available place into a new Bank of America account?",
  boaAccountNewMerrill_add: "How much combined value do you have available to add to your Merrill accounts?",
  boaAccountNewMerrill_new: "How much combined value do you have available to place into a new Merrill account?",
  bookDirect: "Are you willing to book travel through third-party serverices or do you prefer to book directly?",
  bookDirect_TF: "Are you willing to book travel through third-party services?",
  cardsAnalyze: "Which cards would you like us to analyze for you?",
  cardsWallet: "Which cards do you already have?",
  giftCards: "Would you be willing to exchange your cash rewards for gift cards for even greater rewards?",
  giftCardsValue: "On average, how much additional value do you get by converting cash rewards to gift cards at each bank?",
  lounges: "Do you regularly visit premium airport lounges?",
  loungesGuest: "How many times each year do you bring a guest with you to airport lounges?",
  loungesPersonal: "How many times do you visit airport lounges each year?",
  pointsValue: "On average, how much additional value do you get by converting rewards points to partner programs?",
  preferredAirline: "Is there a domestic airline for which you regularly incur more incidental fees (e.g. baggage fees) than any other domestic airline?",
  securityClear: "Are you currently enrolled or do you plan to enroll in Clear?",
  securityGlobalEntry: "Are you currently enrolled or do you plan to enroll in Global Entry?",
  securityPreCheck: "Are you currently enrolled or do you plan to enroll in TSA PreCheck?",
  sofiDirectDeposit: "Do you have or would you be willing to set up a free checking/savings account with direct deposit at SoFi?",
  spending: "On average, how much do you spend each",
  spending_additional: "in additional categories:",
  spending_airlineIncidentals: "in incidental airline expenses:",
  spending_standard: "in the following categories:",
  travelInternationally: "Do you regularly travel internationally?",
}

export default questions
