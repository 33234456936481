import React, {useEffect} from 'react'

const Privacy = (props) => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return ( <>
    <h1>Privacy Policy for StackMyDeck.com</h1>

<p>Last updated January 28, 2024</p>

<p>At StackMyDeck, accessible from stackmydeck.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by StackMyDeck and how we use it.</p>

<p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was generated with the help of <a href="https://www.gdprprivacypolicy.net/">GDPR Privacy Policy Generator from GDPRPrivacyPolicy.net</a></p>

<h2>General Data Protection Regulation (GDPR)</h2>
<p>We are a Data Controller of your information.</p>

<p>StackMyDeck.com legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
<ul>
    <li>StackMyDeck.com needs to perform a contract with you</li>
    <li>You have given StackMyDeck.com permission to do so</li>
    <li>Processing your personal information is in StackMyDeck.com legitimate interests</li>
    <li>StackMyDeck.com needs to comply with the law</li>
</ul>

<p>StackMyDeck.com will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

<p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>

<p>In certain circumstances, you have the following data protection rights:</p>
<ul>
    <li>The right to access, update or to delete the information we have on you.</li>
    <li>The right of rectification.</li>
    <li>The right to object.</li>
    <li>The right of restriction.</li>
    <li>The right to data portability</li>
    <li>The right to withdraw consent</li>
</ul>

<h2>Log Files</h2>

<p>StackMyDeck follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

<h2>Cookies and Web Beacons</h2>

<p>Like any other website, StackMyDeck uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

<p>For more general information on cookies, please read <a href="https://www.generateprivacypolicy.com/#cookies">"Cookies" article from the Privacy Policy Generator</a>.</p>


<h2>Our Affiliate Partners</h2>

<p>Some affiliate partners on our site may use cookies and web beacons. Our affiliate partners are listed below. Each of our affiliate partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>

<ul>
    <li>
        <p>Google</p>
        <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
    </li>
    <li>
        <p>Pollfish</p>
        <p><a href="https://www.pollfish.com/terms/respondent">https://www.pollfish.com/terms/respondent</a></p>
    </li>
</ul>

<h2>Privacy Policies</h2>

<p>You may consult this list to find the Privacy Policy for each of the affiliate partners of StackMyDeck.</p>

<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on StackMyDeck, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

<p>Note that StackMyDeck has no access to or control over these cookies that are used by third-party advertisers.</p>

<h2>Pollfish Privacy</h2>

<p>This website uses Pollfish web plugin. Pollfish is an on-line offer platform, through which, app users are exposed to market research surveys and/or third-party ads (“Offers”). . Pollfish collaborates with publishers of applications for smartphones and website owners in order to have access to users of such applications and address Offers to them. This website uses and enables Pollfish cookies in order for Pollfish to be able to serve Offers through our website. Pollfish collects and processes your data in accordance with all applicable law requirements. For a full list of data received by Pollfish through this app, and for further information on how Pollfish processes such data please read carefully Pollfish’s Privacy Policy for users of Apps collaborating with Pollfish available at https://www.pollfish.com/terms/respondent. Your data may be transferred by Pollfish to non-EEA countries in accordance with all applicable law requirements, including use of EU Commission Standard Contractual Clauses. Pollfish may share such data with third parties, clients and business partners, for commercial purposes. By using this website you accept this privacy policy document and you hereby give your consent for the processing by Pollfish of the aforementioned data. Furthermore, you are informed that you may disable Pollfish operation at any time by visiting the following link https://www.pollfish.com/respondent/opt-out We once more invite you to check the Pollfish Privacy Policy, if you wish to have more detailed view of the way Pollfish works and with whom Pollfish may further share your data.</p>

<h2>Third Party Privacy Policies</h2>

<p>StackMyDeck's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

<p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

<h2>Children's Information</h2>

<p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

<p>StackMyDeck does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

<h2>Online Privacy Policy Only</h2>

<p>Our Privacy Policy created at GDPRPrivacyPolicy.net) applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in StackMyDeck. This policy is not applicable to any information collected offline or via channels other than this website.</p>

<h2>Consent</h2>

<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
  </> )
}

export default Privacy
