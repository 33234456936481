const calculateStatusBoa = (boa) => {
  const total = boa.cash + boa.cashNew + boa.investments + boa.investmentsNew
  if (total >= 100000) {
    return 1.75
  }
  else if (total >= 50000) {
    return 1.5
  }
  else if (total >= 25000) {
    return 1.25
  }
  else {
    return 1
  }
}

export default calculateStatusBoa
