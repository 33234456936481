import {useEffect} from 'react'

import {Typography, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

const BankStatus = (templateData) => {
  const classes = useStyles()

  useEffect(() => {
    if (templateData.pageState.state.inputs.userInput.inputViews.sofiDirectDeposit == null) {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: true,
      })
    }
    else {
        templateData.pageState.setState({
            ...templateData.pageState.state,
            disableNext: false,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDirectDeposit = (directDeposit) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.sofiDirectDeposit = directDeposit
    templateData.pageState.setState({
      ...templateData.pageState.state,
      disableNext: false,
    })
  }

  return <form>
    <Typography variant="h5">
    {questions.sofiDirectDeposit}
    <FormGroup
    className={classes.spacing}
    >
        <Box
        component="span"
        className={classes.spreadBoxCenter}
        >
            <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.sofiDirectDeposit === true ? "default" : "secondary"}
            onClick={() => handleDirectDeposit(false)}
            >
            No
            </Button>
            <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.sofiDirectDeposit === false ? "default" : "primary"}
            onClick={() => handleDirectDeposit(true)}
            >
            Yes
            </Button>
        </Box>
      </FormGroup>
    </Typography>
  </form>
}

export default BankStatus
