import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const PreferredAirline = (inputs) => {
  const classes = useStyles()

  return <Box>
    <Typography variant="h5">Preferred Airline</Typography>
    <Box className={classes.spacingHalf}>
      <Typography variant="body1">{questions.preferredAirline}</Typography>
      <YesNo bool={inputs.userInput.inputViews.preferredAirline}/>
    </Box>
  </Box>
}

export default PreferredAirline
