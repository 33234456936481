

import {Typography, TextField, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import questions from '../../../constants/questions'
import blurSpend from '../../../fns/blurSpend'
import focusSpend from '../../../fns/focusSpend'
import getValueSpend from '../../../fns/getValueSpend'
import handleChangeAnnual from '../../../fns/handleChangeAnnual'
import handleChangeSpend from '../../../fns/handleChangeSpend'
import {useStyles} from '../../../themes/styles'

import DoubleSwitch from '../../common/DoubleSwitch'

const PurchasesOther = (templateData) => {
  const classes = useStyles()

  const addNewOther = (event) => {
    const newStr = templateData.pageState.state.newOther
    templateData.pageState.state.inputs.userInput.spending.otherStr.push(newStr)
    templateData.pageState.state.inputs.userInput.spending.otherDomestic.push(0)
    templateData.pageState.state.inputs.userInput.spending.otherForeign.push(0)
    templateData.pageState.setState({
      ...templateData.pageState.state,
      newOther: "",
    })
  }

  const handleChange = (event) => {
    templateData.pageState.setState({
      ...templateData.pageState.state,
      newOther: event.target.value,
    })
  }

  const removeOther = (index) => {
    templateData.pageState.state.inputs.userInput.spending.otherStr.splice(index,1)
    templateData.pageState.state.inputs.userInput.spending.otherDomestic.splice(index,1)
    templateData.pageState.state.inputs.userInput.spending.otherForeign.splice(index,1)
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  return <form>
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography onClick={event => handleChangeAnnual(templateData, false)} className={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? classes.clickGrey : classes.clickBlack}>Input monthly spending</Typography>
      <DoubleSwitch onChange={event => handleChangeAnnual(templateData, event.target.checked)} checked={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual}/>
      <Typography onClick={event => handleChangeAnnual(templateData, true)} className={templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? classes.clickBlack : classes.clickGrey}>Input annual spending</Typography>
    </Stack>
    <Typography variant="h5">
      {questions.spending} {templateData.pageState.state.inputs.userInput.inputViews.inputAnnual ? "year" : "month"} {questions.spending_additional}
    </Typography>
    <FormGroup
    className={classes.spacing}
    >
      {templateData.pageState.state.inputs.userInput.spending.otherStr.map((step, index) => (
        <Box key={String(index)} className={classes.spacing}>
          <Typography variant="h6">{step}</Typography>
          <Grid container spacing={2} className={classes.spacingHalf}>
            <Grid item xs={6}>
              <TextField
              className={classes.spacingHalf}
              id={"otherDomestic__" + String(index)}
              label={templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ? "Domestic Spending" : ""}
              variant="outlined"
              value = {getValueSpend(templateData, "otherDomestic", index)}
              onFocus = {(event => focusSpend(templateData, "otherDomestic", index))}
              onChange = {(event => handleChangeSpend(templateData, "otherDomestic", index, event.target.value))}
              onBlur = {(event => blurSpend(templateData, "otherDomestic", index))}
              />
            </Grid>
            <Grid item xs={6}>
              {templateData.pageState.state.inputs.userInput.inputViews.travelInternationally ?
                <TextField
                className={classes.spacingHalf}
                id={"otherForeign__" + Number(index)}
                label="Foreign Spending"
                variant="outlined"
                value = {getValueSpend(templateData, "otherForeign", index)}
                onFocus = {(event => focusSpend(templateData, "otherForeign", index))}
                onChange = {(event => handleChangeSpend(templateData, "otherForeign", index, event.target.value))}
                onBlur = {(event => blurSpend(templateData, "otherForeign", index))}
                />
              :
                <></>
              }
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              className={classes.spacingHalf}
              variant="contained"
              color="secondary"
              onClick={() => removeOther(index)}
            >
              Remove
            </Button>
          </Grid>
        </Box>
      ))}
      {templateData.pageState.state.inputs.userInput.spending.otherStr.length < 20 ?
        <>
          <Typography variant="h5" className={classes.spacing}>
            New Category:
          </Typography>
          <TextField
          className={classes.spacingHalf}
          id={"otherStr"}
          variant="outlined"
          value = {templateData.pageState.state.newOther}
          onChange = {handleChange}
          />
          <Grid container justifyContent="flex-end">
            <Button
              className={classes.spacing}
              variant="contained"
              color="primary"
              onClick={addNewOther}
              disabled={!Boolean(templateData.pageState.state.newOther)}
            >
              Add +
            </Button>
          </Grid>
        </>
      :
        <></>
      }

    </FormGroup>
  </form>
}

export default PurchasesOther
