import getCardInfo from './getCardInfo'

const calcSummary = (state) => {
  const inputs = state.inputs
  const result = state.inputs.result.optima

  // Lounge visit initial calculation
  const loungeVisits = inputs.userInput.loungeVisits
  var costLoungeNoCard = 0
  if (loungeVisits.personal) {
    var costStandard = 99 + (35 * (loungeVisits.personal + loungeVisits.guest))
    var costStandardPlus = 329 + (35 * ((loungeVisits.personal > 10 ? loungeVisits.personal - 10 : 0) + loungeVisits.guest))
    var costPrestige = 469 + (35 * loungeVisits.guest)
    costLoungeNoCard = Math.min(costStandard, costStandardPlus, costPrestige)
  }

  // Decode results for UI
  let resultConsolidated = []
  for (let iResult = 0; iResult < result.length; iResult++) {
    if (result[iResult]) { // not null

      // Level 1 title
      var title = "Current Deck"
      if (iResult) {
        var charPlus = ""
        if (result[0]) {
          charPlus = "+"
        }
        if (inputs.tier.fullAnalysis) {
          if (iResult > 1) {
            title = (charPlus + iResult + " Cards")
          }
          else {
            title = charPlus + "1 Card"
          }
        }
        else {
          let deck = result[iResult].deck
          for (var iDeck = 0; iDeck < deck.length; iDeck++) {
            if (!inputs.cardsWallet.includes(deck[iDeck])) {
              title = (charPlus + " " + getCardInfo(deck[iDeck]).display)
            }
          }
        }
      }

      // Initialize cards in iResult
      let cardsResult = []
      let cardsResultIdcs = {}
      let redemptionBonuses = {
        citi: 0,
      }
      for (let iDeck = 0; iDeck < result[iResult].deck.length; iDeck++) {
        cardsResultIdcs[result[iResult].deck[iDeck]] = iDeck
        cardsResult.push({
          card: getCardInfo(result[iResult].deck[iDeck]),
          config: result[iResult].optimumSpending.idcsConfigs[iDeck],
          categories: {
            domestic: [],
            foreign: [],
          },
          rebates: {
            total: 0,
            itemized: [],
          },
          spending: {
            domestic: {
              base: 0,
              bonus: 0,
              conversion: 0,
              details: {
                totals: [],
                categories: [],
                idcs: {},
              },
            },
            foreign: {
              base: 0,
              bonus: 0,
              conversion: 0,
              details: {
                totals: [],
                categories: [],
                idcs: {},
              },
              totalSpend: 0,
            },
          },
          additionalValue: {
            lounge: 0,
            clear: 0,
            redemption: 0,
            tsa: 0,
          },
        })
      }

      // Distribute result spending to assigned cards
      for (let iSpending = 0; iSpending < result[iResult].optimumSpending.howToSpend.length; iSpending++) {
        let spendingDetail = result[iResult].optimumSpending.howToSpend[iSpending]
        let categorySplit = spendingDetail.category.split("__")
        let spendingSummary = cardsResult[cardsResultIdcs[spendingDetail.card]].spending[categorySplit[0]]

        if (spendingDetail.rebateNote) {
          cardsResult[cardsResultIdcs[spendingDetail.card]].rebates.total += Math.round(spendingDetail.reward)
          cardsResult[cardsResultIdcs[spendingDetail.card]].rebates.itemized.push({
            category: spendingDetail.category,
            value: Math.round(spendingDetail.reward),
            note: spendingDetail.rebateNote,
          })
        }
        else {
          let baseReward = spendingDetail.amount * (spendingDetail.rateBreakdown.base / 100)
          let bonusRedeem = baseReward * (spendingDetail.rateBreakdown.redeem - 1)
          let bonusMultiplier = (baseReward + bonusRedeem) * (spendingDetail.rateBreakdown.multiplier - 1)
          spendingSummary.base += baseReward
          if (
            (spendingDetail.card.split("_")[0] === 'citi')
            &&
            (result[iResult].deck.includes('citi_rewardsPlus'))
            &&
            (spendingDetail.card !== 'citi_rewardsPlus')
          ) {
            redemptionBonuses.citi += (bonusRedeem + bonusMultiplier)
          }
          else {
            spendingSummary.bonus += bonusRedeem
            spendingSummary.conversion += bonusMultiplier
          }
        }
        if (categorySplit[0] === "foreign") {
          spendingSummary.totalSpend += spendingDetail.amount
        }

        if (spendingSummary.details.categories.includes(categorySplit[1])) {
          spendingSummary.details.totals[spendingSummary.details.idcs[categorySplit[1]]].total += spendingDetail.amount
        }
        else {
          spendingSummary.details.idcs[categorySplit[1]] = spendingSummary.details.totals.length
          spendingSummary.details.totals.push({
            total: spendingDetail.amount,
            category: categorySplit[1],
          })
          spendingSummary.details.categories.push(categorySplit[1])
        }
      }

      // Round out results and set total per card
      for (let iCard = 0; iCard < cardsResult.length; iCard++) {
        cardsResult[iCard].spending.domestic.base = Math.round(cardsResult[iCard].spending.domestic.base)
        cardsResult[iCard].spending.domestic.bonus = Math.round(cardsResult[iCard].spending.domestic.bonus)
        cardsResult[iCard].spending.domestic.conversion = Math.round(cardsResult[iCard].spending.domestic.conversion)
        cardsResult[iCard].spending.domestic.total = cardsResult[iCard].spending.domestic.base + cardsResult[iCard].spending.domestic.bonus + cardsResult[iCard].spending.domestic.conversion

        cardsResult[iCard].spending.foreign.base = Math.round(cardsResult[iCard].spending.foreign.base)
        cardsResult[iCard].spending.foreign.bonus = Math.round(cardsResult[iCard].spending.foreign.bonus)
        cardsResult[iCard].spending.foreign.conversion = Math.round(cardsResult[iCard].spending.foreign.conversion)
        cardsResult[iCard].spending.foreign.fees = Math.round(cardsResult[iCard].spending.foreign.totalSpend * (cardsResult[iCard].card.fees.foreign / 100))
        cardsResult[iCard].spending.foreign.total = cardsResult[iCard].spending.foreign.base + cardsResult[iCard].spending.foreign.bonus + cardsResult[iCard].spending.foreign.conversion// - cardsResult[iCard].spending.foreign.fees

        cardsResult[iCard].total = cardsResult[iCard].rebates.total + cardsResult[iCard].spending.domestic.total + cardsResult[iCard].spending.foreign.total - cardsResult[iCard].card.fees.annual - cardsResult[iCard].spending.foreign.fees
      }

      // Citi Rewards Plus bonus
      if (redemptionBonuses.citi) {
        cardsResult[cardsResultIdcs["citi_rewardsPlus"]].additionalValue.redemption = Math.round(redemptionBonuses.citi)
        cardsResult[cardsResultIdcs["citi_rewardsPlus"]].total += Math.round(redemptionBonuses.citi)
      }

      // Clear value
      for (let iCard = 0; iCard < cardsResult.length; iCard++) {
        if (result[iResult].benefitClear) {
          if (cardsResult[iCard].card.db === "amex_platinum") {
            cardsResult[iCard].additionalValue.clear = 189
            cardsResult[iCard].total += cardsResult[iCard].additionalValue.clear
          }
          else if ((cardsResult[iCard].card.db === "amex_green") && (!result[iResult].deck.includes("amex_platinum"))) {
            cardsResult[iCard].additionalValue.clear = 189
            cardsResult[iCard].total += cardsResult[iCard].additionalValue.clear
          }
        }
      }
      cardsResult.sort((a,b) => {
        return b.total - a.total
      })

      // Lounge value
      if (result[iResult].loungeExpense.benefit) {
        if ((result[iResult].deck.includes("amex_platinum")) || (result[iResult].deck.includes("boa_premiumElite")) || (result[iResult].deck.includes("cap1_ventureX")) || (result[iResult].deck.includes("chase_sapphireReserve"))) {
          for (let iCard = 0; iCard < cardsResult.length; iCard++) {
            let cardName = cardsResult[iCard].card.db
            if ((cardName === "amex_platinum") || (result[iResult].deck.includes("boa_premiumElite")) || (cardName === "cap1_ventureX") || (cardName === "chase_sapphireReserve")) {
              cardsResult[iCard].additionalValue.lounge = costLoungeNoCard
              cardsResult[iCard].total += cardsResult[iCard].additionalValue.lounge
              break
            }
          }
        }
        else if (result[iResult].deck.includes("usbank_altitudeReserve")) {
          for (let iCard = 0; iCard < cardsResult.length; iCard++) {
            if (cardsResult[iCard].card.db === "usbank_altitudeReserve") {
              const costCard = 35 * (Math.max(loungeVisits.personal - 4, 0) + Math.max(loungeVisits.guest - 4, 0))
              const costCardStandardPlus = 329 + (35 * (Math.max(loungeVisits.personal - (4 + 10), 0) + Math.max(loungeVisits.guest - 4, 0)))
              const costCardPrestige = 469 + (35 * Math.max(loungeVisits.guest - 4, 0))
              const minCard = Math.min(costCard, costCardStandardPlus, costCardPrestige)
              cardsResult[iCard].additionalValue.lounge = costLoungeNoCard - minCard
              cardsResult[iCard].additionalValue.loungeNote = result[iResult].loungeExpense.note
              cardsResult[iCard].total += cardsResult[iCard].additionalValue.lounge
              break
            }
          }
        }
      }
      cardsResult.sort((a,b) => {
        return b.total - a.total
      })

      // TSA value
      if (result[iResult].benefitTSA) {
        for (let iCard = 0; iCard < cardsResult.length; iCard++) {
          if (cardsResult[iCard].card.benefitTSA) {
            cardsResult[iCard].additionalValue.tsa = result[iResult].benefitTSA
            cardsResult[iCard].total += cardsResult[iCard].additionalValue.tsa
            break
          }
        }
      }
      cardsResult.sort((a,b) => {
        return b.total - a.total
      })

      // Calculate total value for iResult
      let totalCardsResult = 0
      for (var iCard = 0; iCard < cardsResult.length; iCard++) {
        totalCardsResult += cardsResult[iCard].total
      }

      resultConsolidated.push({
        result: result[iResult],
        cardsResult,
        title,
        total: totalCardsResult,
      })
    }
  }

  // Organize final results and eliminate unnecessary results
  if (inputs.tier.fullAnalysis) {
    for (let iResult = 1; iResult < resultConsolidated.length; iResult++) {
      if (resultConsolidated[iResult].total <= resultConsolidated[iResult-1].total) {
        resultConsolidated.splice(iResult, resultConsolidated.length)
        break
      }
    }
  }
  else {
    if (inputs.cardsWallet.length) {
      var resultsWallet = resultConsolidated.splice(0,1)
    }
    else {
      resultsWallet = []
    }
    resultConsolidated.sort((a,b) => {
      if (a.cardsResult.length - b.cardsResult.length) {
        return a.cardsResult.length - b.cardsResult.length
      }
      return b.total - a.total
    })
    resultConsolidated = [
      ...resultsWallet,
      ...resultConsolidated,
    ]
  }

  // Notes
  let notes = []
  const noteTravel = "To maximize the rewards in travel categories for some cards, you will be required to purchase travel through the credit card issuer's travel portal. You should always check the constraints on each card to see how to maximize your rewards."
  notes.push(noteTravel)
  if (costLoungeNoCard) {
    var loungeDetails = {
      plan: "",
      desc: "",
    }
    if (costLoungeNoCard === costPrestige) {
      loungeDetails = {
        plan: "Prestige",
        desc: "$469 + $35 per guest visit",
      }
    }
    else if (costLoungeNoCard === costStandardPlus) {
      loungeDetails = {
        plan: "Standard Plus",
        desc: "$329 for 10 free Member visits and $35 for each visit after that and all guest visits",
      }
    }
    else {
      loungeDetails = {
        plan: "Standard",
        desc: "$99 plus $35 per visit for each person",
      }
    }
    const noteLounge = ("Without any card benefits, your least expensive option for premium lounge visits would be the Priority Pass " + loungeDetails.plan + " Plan (" + loungeDetails.desc + ") for a total expense of $" + costLoungeNoCard)
    notes.push(noteLounge)
  }
  if (inputs.userInput.multipliers.status.boa > 1) {
    var breakLoopStatusBoa = false
    const tierVals = [
      100000,
      50000,
      20000,
    ]
    for (let iResult = 0; iResult < resultConsolidated.length; iResult++) {
      if (!breakLoopStatusBoa) {
        for (let iCard = 0; iCard < resultConsolidated[iResult].result.deck.length; iCard++) {
          if (resultConsolidated[iResult].result.deck[iCard].split("_")[0] === "boa") {
            const valueCurrent = inputs.userInput.boa.cash + inputs.userInput.boa.investments
            const valueAdd = inputs.userInput.boa.cashNew + inputs.userInput.boa.investmentsNew
            const valueTotal = valueCurrent + valueAdd
            var boaTier = ""
            var boaThreshold = 0
            var needToAdd = 0
            if (valueTotal >= tierVals[0]) {
              boaTier = "Platinum Honors"
              boaThreshold = tierVals[0]
              if (valueCurrent < tierVals[0]) {
                needToAdd = tierVals[0] - valueCurrent
              }
            }
            else if (valueTotal > tierVals[1]) {
              boaTier = "Platinum"
              boaThreshold = tierVals[1]
              if (valueCurrent < tierVals[1]) {
                needToAdd = tierVals[1] - valueCurrent
              }
            }
            else {
              boaTier = "Gold"
              boaThreshold = tierVals[2]
              if (valueCurrent < tierVals[2]) {
                needToAdd = tierVals[2] - valueCurrent
              }
            }
            var strAdd = ""
            var strAction = ""
            if (valueCurrent) {
              strAction = `maintaining a combined value of at least $${boaThreshold/1000},000 in your combined Bank of America and Merrill accounts.`
              if (needToAdd) {
                strAdd = " You'll need to add at least $" + needToAdd + " to achieve this."
              }
            }
            else {
              strAction = `opening a new Bank of America and/or Merrill account with a combined value of at least $${boaThreshold/1000},000.`
            }
            const strTier = "To maximize the rewards for any Bank of America cards, you'll need to maintain a " + boaTier + " level status in their Preferred Rewards program. You can do this by "
            const noteStatusBoa = strTier + strAction + strAdd
            notes.push(noteStatusBoa)
            breakLoopStatusBoa = true
            break
          }
        }
      }
      else {
        break
      }
    }
  }
  var resultsIncludeCitiRewardsPlus = false
  for (let iResult = 0; iResult < resultConsolidated.length; iResult++) {
    if (!resultsIncludeCitiRewardsPlus) {
      for (let iCard = 0; iCard < resultConsolidated[iResult].result.deck.length; iCard++) {
        if (resultConsolidated[iResult].result.deck[iCard] === "citi_rewardsPlus") {
          resultsIncludeCitiRewardsPlus = true
          const noteCitiRewardsPlus = "With the Citi Rewards Plus card, get 10% Points Back when you redeem, up to the first 100,000 points redeemed per year. This includes points earned from other Citi credit cards."
          notes.push(noteCitiRewardsPlus)
          break
        }
      }
    }
    else {
      break
    }
  }

  return {
    resultConsolidated,
    notes,
  }
}

export default calcSummary
