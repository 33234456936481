import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"

import axios from "axios"
import {toast} from "react-toastify"

import {Typography, TextField, Button} from "@material-ui/core"

import inputLimits from '../../constants/inputLimits'
import PageAfterLogin from '../../constants/pageAfterLogin'
import isEmail from '../../fns/isEmail'
import {loadingToggle} from "../../store/actions/appActions"
import {urlApi} from "../../api"
import {useStyles} from '../../themes/styles'

const PwRecovery = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    setEmail(val)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(loadingToggle(true))
    const errorEmail = await validateEmail()
    if (errorEmail) {
      dispatch(loadingToggle(false))
    }
    else {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
          let request = {
            email,
            recaptchaToken: token,
          }
          axios
          .post(`${urlApi}/user/pwRecover`, request, {"withCredentials": true})
          .then((res) => {
            setSubmitted(true)
            dispatch(loadingToggle(false))
          })
          .catch(error => {
            console.log(error.response)
            toast.error(error.response?.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            })
            dispatch({
              type: "LOADING_TOGGLE",
              isLoading: false,
            })
          })
        })
      })
    }
  }

  const validateEmail = () => {
    var error = ""
    if (!email.length) {
      error = "Please enter your email"
    }
    else if (!isEmail(email)) {
      error = "Please enter a valid email address"
    }
    setError(error)
    return error
  }

  if (auth.name) {
    return <PageAfterLogin/>
  }

  if (submitted) {
    return <Typography variant="h6" className={classes.shadowBox}>
      Please check your email for instructions on resetting your password.
    </Typography>
  }

  return (
    <form
    className={classes.shadowBox}
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
      <Typography variant="h4" sx={{}}>Reset Password</Typography>
      <TextField
        id="enter-email"
        className={classes.spacing}
        label="Enter Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange = {(event) => changeVal(event, "email")}
        error={Boolean(error)}
        helperText={error}
        onBlur={() => validateEmail()}
      />
      <Button
        className={classes.spacing}
        variant="contained"
        color="primary"
        type="submit"
      >
        Submit
      </Button>
    </form>
  )
}

export default PwRecovery