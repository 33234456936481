import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from "react-redux"
import {createStore, applyMiddleware} from "redux"
import thunk from "redux-thunk"

import rootReducer from "./store/reducers/rootReducer"

import App from './App'
import reportWebVitals from './reportWebVitals'

const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
    <Provider store = {store}>
      <div>
        <style>{`
          body {
            margin: 0px;
            padding: 0px;
          }
        `}</style>
      </div>
      <App />
    </Provider>,
  document.getElementById('root')
)

reportWebVitals()
