const focusSpend = (templateData, domain, field) => {
  let spending = templateData.pageState.state.inputs.userInput.spending
  const spendingDomainField = spending[domain][field]
  if (!spendingDomainField) {
    spending[domain][field] = ''
  }
  else {
    if (templateData.pageState.state.inputs.userInput.inputViews.inputAnnual) {
      spending[domain][field] = (spending[domain][field]).toFixed(0)
    }
    else {
      spending[domain][field] = (spending[domain][field] / 12).toFixed(2)
    }
  }
  templateData.pageState.setState({
    ...templateData.pageState.state,
  })
}

export default focusSpend
