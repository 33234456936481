import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"

import axios from "axios"
import {toast} from "react-toastify"

import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

import inputLimits from '../../../constants/inputLimits'
import encrypt from '../../../fns/encrypt'
import isEmail from '../../../fns/isEmail'
import {useStyles} from '../../../themes/styles'

import {urlApi} from "../../../api"
import {loadingToggle} from "../../../store/actions/appActions"


const DialogGift = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const defaultVals = {
    email1: "",
    email2: "",
    message: "",
    name: auth.name,
    password: "",
  }

  const [gift, setGift] = useState({...defaultVals})
  const [errors, setErrors] = useState({...defaultVals})

  const changeVal = (event, typeLimit, typeUser) => {
    if (!typeUser) {
      typeUser = typeLimit
    }
    var val = event.target.value
    const max = inputLimits[typeLimit].max
    if (val.length > max) {
      val = val.slice(0, max)
    }
    gift[typeUser] = val
    setGift({...gift})
  }

  const handleClose = () => {
    props.setOpen(false)
    setGift({...defaultVals})
    setErrors({...defaultVals})
  }

  const handleSend = async () => {
    const errorEmail1 = await validateEmail1()
    const errorEmail2 = await validateEmail2()
    const errorPassword = await validatePassword()
    if (errorEmail1 || errorEmail2 || errorPassword) {
      toast.error("Please correct any errors and try again", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      })
    }
    else {
      const request = {
        idAnalysis: props.open,
        email: gift.email1,
        message: gift.message,
        name: gift.name,
        password: encrypt(gift.password),
      }
      setGift({
        ...gift,
        password: '',
      })
      dispatch(loadingToggle(true))
      axios
      .post(`${urlApi}/analysis/giveGift`, request, {"withCredentials": true})
      .then(resp => {
        handleClose()
        dispatch(loadingToggle(false))
        toast("Your gift has been sent", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
      })
      .catch(error => {
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        })
        dispatch({
          type: "LOADING_TOGGLE",
          isLoading: false,
        })
      })
    }
  }

  const validateEmail1 = () => {
    var error1 = ""
    var error2 = errors.email2
    if (!isEmail(gift.email1)) {
      error1 = "Please enter a valid email address"
    }
    if (gift.email1) {
      if (gift.email2 === gift.email1) {
        error2 = ""
      }
    }
    setErrors({
      ...errors,
      email1: error1,
      email2: error2,
    })
    return error1
  }

  const validateEmail2 = () => {
    var error = ""
    if (gift.email2 !== gift.email1) {
      error = "Email addresses do not match"
    }
    setErrors({
      ...errors,
      email2: error,
    })
    return error
  }

  const validatePassword = () => {
    var error = ""
    if (!gift.password) {
      error = "Please enter your password"
    }
    setErrors({
      ...errors,
      password: error,
    })
    return error
  }

  return (
    <div>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h5">Give Gift</Typography>
          <Typography variant="h6">{props.strAnalysis}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The recipient will be notified via email that a gift is waiting for them.
          </DialogContentText>
          <Box className={classes.spacing}>
            <TextField
              id="name"
              label="Enter Your Name"
              variant="outlined"
              fullWidth
              value={gift.name}
              onChange = {(event) => changeVal(event, "name")}
            />
          </Box>
          <Box className={classes.spacing}>
            <TextField
              id="enter-email"
              label="Enter Recipient Email"
              variant="outlined"
              fullWidth
              value={gift.email1}
              onChange = {(event) => changeVal(event, "email", "email1")}
              error={Boolean(errors.email1)}
              helperText={errors.email1}
              onBlur={() => validateEmail1()}
            />
          </Box>
          <Box className={classes.spacing}>
            <TextField
              id="reenter-email"
              label="Re-enter Recipient Email"
              variant="outlined"
              fullWidth
              value={gift.email2}
              onChange = {(event) => changeVal(event, "email", "email2")}
              error={Boolean(errors.email2)}
              helperText={errors.email2}
              onBlur={() => validateEmail2()}
            />
          </Box>
          <Box className={classes.spacing}>
            <TextField
              id="outlined-multiline-static"
              label="Message (Optional)"
              fullWidth
              multiline
              rows={4}
              value={gift.message}
              onChange = {(event) => {
                changeVal(event, "message")
              }}
            />
            <Typography variant="body1">{gift.message.length} / {inputLimits.message.max}</Typography>
          </Box>
          <Box className={classes.spacing}>
            <TextField
              id="enter-password"
              type="password"
              label="Enter Your Password"
              variant="outlined"
              fullWidth
              value={gift.password}
              onChange = {(event) => changeVal(event, "password")}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSend} variant="contained">Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogGift