import * as React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import CardDetails from '../../common/cardDetails'

const DialogCard = (props) => {
  const handleClose = () => {
    props.setOpen(false)
  }

  if (!props.card) {
    return null
  }

  return (
    <div>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>{props.card.display}</DialogTitle>
        <DialogContent>
          <CardDetails {...props.card}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogCard