import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import questions from '../../../constants/questions'
import getBanksActiveCash from '../../../fns/getBanksActiveCash'
import {useStyles} from '../../../themes/styles'

import YesNo from '../../common/YesNo'

const MultipliersCash = (inputs) => {
  const classes = useStyles()

  const banksActive = getBanksActiveCash([...inputs.cardsWallet, ...inputs.cardsAnalyze])

  return <Box>
    <Typography variant="h5">Gift Card Bonus</Typography>
    <Box className={classes.spacingHalf}>
      <Typography variant="body1">{questions.giftCards}</Typography>
      <YesNo bool={inputs.userInput.inputViews.giftCards}/>
    </Box>
    {inputs.userInput.inputViews.giftCards ?
      <Box>
        <Typography variant="body1" className={classes.spacingHalf}>{questions.giftCardsValue}</Typography>
        <table>
          {banksActive.map((step, index) => (
            <tr>
              <td><Typography variant="body1">{step.disp}:</Typography></td>
              <td><Typography variant="body1" className={classes.fontBold + " " + classes.spacingLeft}>
                {(inputs.userInput.multipliers.cash[step.db]/(inputs.userInput.multipliers.status[step.db] ? inputs.userInput.multipliers.status[step.db] : 1)).toFixed(2)}
              </Typography></td>
            </tr>
          ))}
        </table>
      </Box>
    :
      null
    }
  </Box>
}

export default MultipliersCash
