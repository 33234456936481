import {useEffect} from 'react'

import {Typography, Button} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

const Security = (templateData) => {
  const classes = useStyles()

  const handleInternational = (travelInternationally) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.travelInternationally = travelInternationally
    //viewResponses.boaAccountNew = null
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const handleSecurity = (field, val) => {
    templateData.pageState.state.inputs.userInput.security[field] = val
    templateData.pageState.setState({
      ...templateData.pageState.state,
      disableNext: !pageFilled(templateData.pageState.state.inputs.userInput.security),
    })
  }

  const pageFilled = (security) => {
    const pcORge = (security.tsaPreCheck != null || security.globalEntry === true)
    const clear = (security.clear != null)

    return (pcORge && clear)
  }

  useEffect(() => {
    if (!pageFilled(templateData.pageState.state.inputs.userInput.security)) {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: true,
      })
    }
    else {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <form>
    <Typography variant="h5">
      {questions.travelInternationally}
      <FormGroup
      className={classes.spacing}
      >
        <Box
        component="span"
        className={classes.spreadBoxCenter}
        >
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.travelInternationally === true ? "default" : "secondary"}
            onClick={() => handleInternational(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.travelInternationally === false ? "default" : "primary"}
            onClick={() => handleInternational(true)}
          >
            Yes
          </Button>
        </Box>
        {templateData.pageState.state.inputs.userInput.inputViews.travelInternationally == null ?
          <></>
        :
          <Box>
            {templateData.pageState.state.inputs.userInput.inputViews.travelInternationally === true ?
              <Box>
                <Box className={classes.spacing}>{questions.securityGlobalEntry}</Box>
                <Box
                component="span"
                className={classes.spreadBoxCenter + " " + classes.spacing}
                >
                  <Button
                    variant="contained"
                    color={templateData.pageState.state.inputs.userInput.security.globalEntry === true ? "default" : "secondary"}
                    onClick={() => handleSecurity("globalEntry", false)}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color={templateData.pageState.state.inputs.userInput.security.globalEntry === false ? "default" : "primary"}
                    onClick={() => handleSecurity("globalEntry", true)}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            :
              <></>
            }
            { ((templateData.pageState.state.inputs.userInput.inputViews.travelInternationally === false) || (templateData.pageState.state.inputs.userInput.security.globalEntry === false)) ?
              <Box>
                <Box className={classes.spacing}>{questions.securityPreCheck}</Box>
                <Box
                component="span"
                className={classes.spreadBoxCenter + " " + classes.spacing}
                >
                  <Button
                    variant="contained"
                    color={templateData.pageState.state.inputs.userInput.security.tsaPreCheck === true ? "default" : "secondary"}
                    onClick={() => handleSecurity("tsaPreCheck", false)}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color={templateData.pageState.state.inputs.userInput.security.tsaPreCheck === false ? "default" : "primary"}
                    onClick={() => handleSecurity("tsaPreCheck", true)}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            :
              <></>
            }
          </Box>
        }
        <Box className={classes.spacing}>{questions.securityClear}</Box>
        <Box
        component="span"
        className={classes.spreadBoxCenter + " " + classes.spacing}
        >
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.security.clear === true ? "default" : "secondary"}
            onClick={() => handleSecurity("clear", false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.security.clear === false ? "default" : "primary"}
            onClick={() => handleSecurity("clear", true)}
          >
            Yes
          </Button>
        </Box>
      </FormGroup>
    </Typography>
  </form>
}

export default Security
