import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesDining = (templateData) => {
  const inputs = [
    {
      db: "dining",
      disp: "Dining at Restaurants",
    },
    {
      db: "dining_delivery",
      disp: "Restaurant Delivery",
    },
    {
      db: "dining_bars",
      disp: "Bars and Lounges",
    },
    {
      db: "dining_bakeries",
      disp: "Bakeries",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesDining
