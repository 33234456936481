import {Typography} from "@material-ui/core"

const BrandedCards = (props) => {

  return ( <>
    <Typography>
      Branded credit cards are those that are issued by stores/hotels/airlines.
      Unfortunately, at this time we are not recommending branded cards.
      While some of these cards can offer wonderful benefits, we find that there are far too many and the rewards are far too restrictive for general recommendations.
      We strive to only recommend cards that have universal benefits for everyone.
    </Typography>
  </> )
}

export default BrandedCards
