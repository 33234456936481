const pageAfterLogin_str = (auth) => {
  if (auth.numPurchases || !auth.hasHistory) {
    return "/analysis"
  }
  else {
    return "/history"
  }

}

export default pageAfterLogin_str
