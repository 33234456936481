const cards = [
  {
    display: "American Express - Blue Cash Everyday",
    db: "amex_blueCashEveryday",
    fees: {
      annual: 0,
      foreign: null,
    },
    link: 'https://www.americanexpress.com/us/credit-cards/card/blue-cash-everyday/',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      '3% on groceries at U.S. supermarkets, on up to $6,000 per year in purchases (then 1%)',
      '3% on U.S. Online Retail Purchases, on up to $6,000 per year in purchases (then 1%)',
      '3% on gas as U.S. gas stations, on up to $6,000 per year in purchases (then 1%)',
      '1% on other purchases',
    ],
    tier: 0,
  },
  {
    display: "American Express - Blue Cash Preferred",
    db: "amex_blueCashPreferred",
    fees: {
      annual: 95,
      foreign: null,
    },
    link: 'https://www.americanexpress.com/us/credit-cards/card/blue-cash-preferred/',
    rewardsAdditional: [
      '$120 Equinox+ Credit annually ($10 monthly statements)',
      'Receive $7 back monthly after spending $9.99+ each month on an eligible subscription to the Disney Bundle',
    ],
    rewardsCash: true,
    rewardsSpending: [
      '6% on groceries up to $6,000 per year in purchases at U.S. supermarkets',
      '6% on select U.S. streaming subscriptions',
      '3% on transit (taxis, rideshare, parking, tolls, trains, buses, and more)',
      '3% on gas at U.S. gas stations',
      '1% on other purchases',
    ],
    tier: 2,
  },
  {
    display: "American Express - Bread Cashback",
    db: "amex_breadCashback",
    fees: {
      annual: 0,
      foreign: null,
    },
    link: 'https://www.breadfinancial.com/en/bread-financial-cashback-card.html',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      '2% unlimited cash back',
    ],
    tier: 0,
  },
  {
    display: "American Express - Bread Rewards",
    db: "amex_breadRewards",
    fees: {
      annual: 0,
      foreign: null,
    },
    link: 'https://www.breadfinancial.com/en/bread-rewards-credit-card.html',
    rewardsAdditional: [
      '25% extra points every billing cycle you make 20 or more purchases',
    ],
    rewardsCash: false,
    rewardsSpending: [
      '3% on gas station, grocery store, dining and utility purchases',
      '1% on all other purchases'
    ],
    tier: 3,
  },
  {
    display: "American Express - Green",
    db: "amex_green",
    fees: {
      annual: 150,
      foreign: null,
    },
    link: 'https://www.americanexpress.com/us/credit-cards/card/green/',
    rewardsAdditional: [
      '$189 CLEAR Plus Credit',
      '$100 LoungeBuddy Credit',
    ],
    rewardsCash: false,
    rewardsSpending: [
      '3x points at restaurants, including takeout and delivery in the U.S.',
      '3x points on transit, including trains, buses, ferries, subway, and more',
      '3x points on travel, including airfare, hotels, cruises, tours, car rentals, and more',
      '1x points on other purchases',
    ],
    tier: 3,
  },
  {
    display: "American Express - Gold",
    db: "amex_gold",
    fees: {
      annual: 250,
      foreign: null,
    },
    link: 'https://www.americanexpress.com/us/credit-cards/card/gold-card/',
    rewardsAdditional: [
      '$120 Uber Cash ($10 in Uber Cash each month)',
      '$120 Dining Credit (earn up to $10 in statement credits monthly at participating partners)',
      '$100 experience credit with a two-night minimum stay when you book The Hotel Collection through American Express Travel'
    ],
    rewardsCash: false,
    rewardsSpending: [
      '4x points on dining at restaurants, including takeout and delivery',
      '4x points on groceries at U.S. supermarkets on up to $25,000 in purchases per year',
      '3x points on flights booked directly with airlines or on amextravel.com',
      '1x points on other eligible purchases'
    ],
    tier: 3,
  },
  {
    display: "American Express - Platinum",
    db: "amex_platinum",
    fees: {
      annual: 695,
      foreign: null,
    },
    link: 'https://www.americanexpress.com/us/credit-cards/card/platinum/',
    rewardsAdditional: [
      '$200 Hotel Credit on select prepaid hotel bookings',
      '$240 Digital Entertainment Credit (Get up to $20 back each month of Peacock, The New York Times, Audible, or SiriusXM)',
      '$155 Walmart+ Credit (Use your Platinum Card to pay for a monthly Walmart+ membership and get the full cost back each month)',
      '$200 Uber Cash ($15 in Uber Cash each month, plus a bonus $20 in December)',
      '$200 Airline Fee Credit (Get up to $200 per year in statement credits for one selected airline)',
      '$189 CLEAR Plus Credit',
    ],
    rewardsCash: false,
    rewardsSpending: [
      '5x points on flights booked directly with airlines or through American Express Travel, on up to $500,000 per calendar year',
      '5x points on prepaid hotels booked on AmexTravel.com',
      '1x points on other purchases',
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Bank of America - Unlimited Cash",
    db: "boa_unlimitedCash",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.bankofamerica.com/credit-cards/products/unlimited-cash-back-credit-card/',
    rewardsAdditional: [
      'Preferred Rewards members earn 25%-75% more cash back on every purchase',
    ],
    rewardsCash: true,
    rewardsSpending: [
      '1.5% on all purchases'
    ],
    tier: 2,
  },
  {
    display: "Bank of America - Customized Cash",
    db: "boa_customizedCash",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.bankofamerica.com/credit-cards/products/cash-back-credit-card/',
    rewardsAdditional: [
      'Preferred Rewards members earn 25%-75% more cash back on every purchase',
    ],
    rewardsCash: true,
    rewardsSpending: [
      "3% cash back in the category of your choice: gas, online shopping, dining, travel, drug stores, or home improvement/furnishings. You'll earn 3% and 2% cash back on the first $2,500 in combined choice category/grocery store/wholesale club purchases each quarter, then earn 1%",
      '2% cash back at grocery stores and wholesale clubs',
      '1% cash back on all other purchases',
    ],
    tier: 1,
  },
  {
    display: "Bank of America - Travel",
    db: "boa_travel",
    fees: {
      annual: 0,
      foreign: 0,
    },
    link: 'https://www.bankofamerica.com/credit-cards/products/travel-rewards-credit-card/',
    rewardsAdditional: [
      'Preferred Rewards members earn 25%-75% more points on every purchase',
    ],
    rewardsCash: false,
    rewardsSpending: [
      "1.5 points on every $1 on all purchases"
    ],
    rewardsNote: "Redeem points for a statement credit to pay for travel and dining purchases",
    tier: 3,
  },
  {
    display: "Bank of America - Premium",
    db: "boa_premium",
    fees: {
      annual: 95,
      foreign: 0,
    },
    link: 'https://www.bankofamerica.com/credit-cards/products/premium-rewards-credit-card/',
    rewardsAdditional: [
      'Get up to $100 in Airline Incidental Statement Credits annually for qualifyiung purchases',
      'Preferred Rewards members earn 25%-75% more cash back on every purchase',
    ],
    rewardsCash: false,
    rewardsSpending: [
      "2 points per $1 spent on travel purchases",
      "2 points per $1 spent on dining purchases",
      "1.5 points per $1 spent on all other purchaess",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Bank of America - Premium Elite",
    db: "boa_premiumElite",
    fees: {
      annual: 550,
      foreign: 0,
    },
    link: 'https://www.bankofamerica.com/credit-cards/products/premium-rewards-elite-credit-card/',
    rewardsAdditional: [
      'Preferred Rewards members earn 25%-75% more cash back on every purchase',
      "Receive up to $150 annually for lifestyle conveniences including streaming services, food delivery, fitness subscriptions and rideshare services",
      "Receive up to $100 in statement credits for either Global Entry or TSA PreCheck® application fees, every four years",
      "Enjoy free unlimited access to airport lounges worldwide with your complimentary Priority Pass™ Select Membership.",
    ],
    rewardsCash: true,
    rewardsSpending: [
      "2 points per $1 spent on travel purchases",
      "2 points per $1 spent on dining purchases",
      "1.5 points per $1 spent on all other purchaess",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Capital One - Quicksilver",
    db: "cap1_quicksilver",
    fees: {
      annual: 0,
      foreign: 0,
    },
    link: 'https://www.capitalone.com/credit-cards/quicksilver/',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on hotels and rental cars booked through Capital One Travel",
      "1.5% cash back on every other purchase"
    ],
    tier: -1,
  },
  {
    display: "Capital One - Savor One",
    db: "cap1_savorOne",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.capitalone.com/credit-cards/savorone-dining-rewards/',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on hotels and rental cars booked through Capital One Travel",
      "3% cash back on dining",
      "3% cash back on entertainment",
      "3% cash back on popular streaming services",
      "3% cash back at grocery stores",
      "1% cash back on all other purchases",
    ],
    tier: 0,
  },
  {
    display: "Capital One - Savor",
    db: "cap1_savor",
    fees: {
      annual: 95,
      foreign: 3,
    },
    link: 'https://www.capitalone.com/credit-cards/savor-dining-rewards/',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on hotels and rental cars booked through Capital One Travel",
      "4% cash back on dining",
      "4% cash back on entertainment",
      "4% cash back on popular streaming services",
      "3% cash back at grocery stores",
      "1% cash back on all other purchases",
    ],
    tier: 2,
  },
  {
    display: "Capital One - Venture One",
    db: "cap1_ventureOne",
    fees: {
      annual: 0,
      foreign: 0,
    },
    link: 'https://www.capitalone.com/credit-cards/ventureone/',
    rewardsAdditional: [],
    rewardsCash: false,
    rewardsSpending: [
      "5x miles on hotels and rental cars booked through Capital One Travel",
      "1.25x miles on all other purchases"
    ],
    tier: 3,
  },
  {
    display: "Capital One - Venture",
    db: "cap1_venture",
    fees: {
      annual: 95,
      foreign: 0,
    },
    link: 'https://www.capitalone.com/credit-cards/venture/',
    rewardsAdditional: [
      "Receive up to a $100 credit for Global Entry or TSA PreCheck"
    ],
    rewardsCash: false,
    rewardsSpending: [
      "5x miles on hotels and rental cars booked through Capital One Travel",
      "2x miles on all other purchases",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Capital One - Venture X",
    db: "cap1_ventureX",
    fees: {
      annual: 395,
      foreign: 0,
    },
    link: 'https://www.capitalone.com/credit-cards/venture-x/',
    rewardsAdditional: [
      "$300 Annual Travel Credit",
      "10,000 bonus miles every year",
      "Complimentary Priority Pass membership with unlimited free visits for you and your guests",
      "Receive up to a $100 credit for Global Entry or TSA PreCheck"
    ],
    rewardsCash: false,
    rewardsSpending: [
      "10x miles on hotels and rental cars booked through Capital One Travel",
      "5x miles on flights booked through Capital One Travel",
      "2x miles on all other purchases",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Chase - Freedom",
    db: "chase_freedom",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: '',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on categories that rotate quarterly",
      "1% cash back on all other purchases",
    ],
    tier: -1,
  },
  {
    display: "Chase - Freedom Flex",
    db: "chase_freedomFlex",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://creditcards.chase.com/cash-back-credit-cards/freedom/flex',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on up to $1,500 on combined purchases in bonus categories each quarter you activate",
      "5% cash back on travel purchased through Chase",
      "3% cash back on dining, including takeout",
      "3% cash back on drugstores",
      "1% on all other purchases",
    ],
    tier: -1,
  },
  {
    display: "Chase - Freedom Unlimited",
    db: "chase_freedomUnlimited",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://creditcards.chase.com/cash-back-credit-cards/freedom/unlimited',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on travel purchased through Chase",
      "3% cash back on dining, including takeout",
      "3% cash back on drugstores",
      "1.5% on all other purchases",
    ],
    tier: 0,
  },
  {
    display: "Chase - Sapphire Preferred",
    db: "chase_sapphirePreferred",
    fees: {
      annual: 95,
      foreign: 0,
    },
    link: 'https://creditcards.chase.com/rewards-credit-cards/sapphire/preferred',
    rewardsAdditional: [
      "Get 25% more value when points are redeemed for travel through Chase Ultimate Rewards",
      "Earn up to $50 in statement credits for hotel stays purchased through Chase Travel",
      "Each account anniversary you'll earn bonus points equal to 10% of your total purchases made the previous year",
    ],
    rewardsCash: false,
    rewardsSpending: [
      "5x points on travel purchases through Chase Ultimate Rewards",
      "2x points on other travel purchases",
      "3x points on dining",
      "3x points on online grocery purchases",
      "1x points on all other purchases",
    ],
    tier: 3,
  },
  {
    display: "Chase - Sapphire Reserve",
    db: "chase_sapphireReserve",
    fees: {
      annual: 550,
      foreign: 0,
    },
    link: 'https://creditcards.chase.com/rewards-credit-cards/sapphire/reserve',
    rewardsAdditional: [
      "$300 Annual Travel Credit",
      "Complimentary Priority Pass membership with unlimited visits",
      "Global Entry or TSA PreCheck Fee Credit every 4 years",
      "Get 50% more value when points are redeemed for travel through Chase Ultimate Rewards",
    ],
    rewardsCash: false,
    rewardsSpending: [
      "10x points on hotel and car rental purchases through Chase Ultimate Rewards",
      "5x points on flight purchases through Chase Ultimate Rewards",
      "3x points on other travel",
      "10x points on Chase Dining purchases with Ultimate Rewards",
      "3x points on other dining",
      "1x points on all other purchases",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Citi - Double Cash",
    db: "citi_doubleCash",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.citi.com/credit-cards/citi-double-cash-credit-card',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "2% cash back on all pruchases (1% when you buy and 1% as you pay)"
    ],
    tier: 0,
  },
  {
    display: "Citi - Custom Cash",
    db: "citi_customCash",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.citi.com/credit-cards/citi-custom-cash-credit-card',
    rewardsAdditional: [
      "5% eligible categories: Restaurants, gas stations, grocery stores, select travel, select transit, select streaming services, drugstores, home improvement stores, fitness clubs, live entertainment."
    ],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on your highest eligible spend category each billing cycle up to the first $500 spent and 1% cash back thereafter",
    ],
    tier: 1,
  },
  {
    display: "Citi - Rewards Plus",
    db: "citi_rewardsPlus",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.citi.com/credit-cards/citi-rewards-plus-credit-card',
    rewardsAdditional: [
      "Get 10% Points Back when you redeem, up to the first 100,000 points redeemed per year (including ThankYou Points earned from other Citi credit cards)",
      "Round up to the nearest 10 points on every purchase"
    ],
    rewardsCash: false,
    rewardsSpending: [
      "2x ThankYou Points per $1 spent at supermarkets and gas stations for the first $6,000 per year and 1 point per $1 spent thereafter",
      "1x ThankYou Points spent on all other purchases",
    ],
    tier: 3,
  },
  {
    display: "Citi - Strata Premier",
    db: "citi_premier",
    fees: {
      annual: 95,
      foreign: 0,
    },
    link: 'https://www.citi.com/credit-cards/citi-premier-credit-card',
    rewardsAdditional: [
      "Enjoy $100 off a single hotel stay of $500 or more, excluding taxes and fees, through thankyou.com, once per calendar year"
    ],
    rewardsCash: false,
    rewardsSpending: [
      "3x points at restaurants",
      "3x points at supermarkets",
      "3x points at gas stations",
      "3x points on air travel and hotels",
      "1x points on all other purchases",
    ],
    tier: 3,
  },
  {
    display: "Discover - It",
    db: "discover_it",
    fees: {
      annual: 0,
      foreign: null,
    },
    link: '',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on everyday purchases at different places each quarter",
      "1% cash back on all other purchases",
    ],
    tier: -1,
  },
  {
    display: "PNC - Cash Rewards",
    db: "pnc_cashRewards",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.pnc.com/en/personal-banking/banking/credit-cards/pnc-cash-rewards-visa-credit-card.html',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      '4% at gas stations',
      '3% on dining',
      '2% at grocery stores',
      '1% on categories above after a combined $8,000 in combined purchases in those categories',
      '1% on all other purchases',
    ],
    tier: 0,
  },
  {
    display: "SoFi Unlimited 2%",
    db: "sofi_unlimited",
    fees: {
      annual: 0,
      foreign: 0,
    },
    link: 'https://www.sofi.com/credit-card/',
    rewardsAdditional: [
      "Earn a 10% boost on credit card purchases for all members who maintain a qualified direct deposit in their SoFi checking or savings account"
    ],
    rewardsCash: true,
    rewardsSpending: [
      "2% cash back rewards",
      "3% cash back rewards on SoFi Travel",
    ],
    tier: 0,
  },
  {
    display: "US Bank - Altitude Go",
    db: "usbank_altitudeGo",
    fees: {
      annual: 0,
      foreign: 0,
    },
    link: 'https://www.usbank.com/credit-cards/altitude-go-visa-signature-credit-card.html',
    rewardsAdditional: [
      "$15 credit for annual streaming service purchases",
    ],
    rewardsCash: false,
    rewardsSpending: [
      "4x points on dining, takeout and restaurant delivery",
      "2x points at grocery stores and grocery delivery",
      "2x points on streaming services",
      "2x points at gas stations",
      "1x points on all other eligible purchases"
    ],
    tier: 0,
  },
  {
    display: "US Bank - Cash+",
    db: "usbank_cashPlus",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://www.usbank.com/credit-cards/cash-plus-secured-visa-credit-card.html',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "5% cash back on your first $2,000 in combined eligible purchases each quarter in two categories you choose",
      "2% cash back on eligible purchases in your choice of one everyday category (like gas stations, grocery stores and restaurants)",
      "1% cash back on all other eligible purchases",
    ],
    tier: -1,
  },
  {
    display: "US Bank - FlexPerks Gold",
    db: "usbank_flexPerksGold",
    fees: {
      annual: 85,
      foreign: null,
    },
    link: 'https://www.usbank.com/credit-cards/flexperks-gold-american-express-credit-card.html',
    rewardsAdditional: [
      "TSA PreCheck or Global Entry statement credit reimbursement on the application fee",
    ],
    rewardsCash: false,
    rewardsSpending: [
      "5x FlexPoints on prepaid hotels and car rentals",
      "3x FlexPoints on dining, takeout and restaurant delivery",
      "2x FlexPoints at airlines and gas stations",
      "1x FlexPoints on everyday spend",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "US Bank - Altitude Connect",
    db: "usbank_altitudeConnect",
    fees: {
      annual: 95,
      foreign: 0,
    },
    link: 'https://www.usbank.com/credit-cards/altitude-connect-visa-signature-credit-card.html',
    rewardsAdditional: [
      "Up to $100 in statement credits to reimburse your TSA PreCheck or Global Entry application fee once every four years",
      "$30 credit for annual streaming service purchases",
    ],
    rewardsCash: false,
    rewardsSpending: [
      "5x points on prepaid hotels and car rentals booked directly in the Altitude Rewards Center",
      "4x points on travel",
      "4x points at gas stations",
      "2x points on grocery stores and grocery delivery",
      "2x points on dining",
      "2x points on streaming services",
      "1x points on all other eligible purchases",
    ],
    benefitTSA: true,
    tier: 2,
  },
  {
    display: "US Bank - Altitude Reserve",
    db: "usbank_altitudeReserve",
    fees: {
      annual: 400,
      foreign: 0,
    },
    link: 'https://www.usbank.com/credit-cards/altitude-reserve-visa-infinite-credit-card.html',
    rewardsAdditional: [
      "Get reimbursed up to $325 annually for eligible travel purchases and takeout, food delivery and dining purchases",
      "Complimentary Priority Pass membership with eight free visits for you and an accompanying guest each year",
      //"Up to $100 in statement credits to reimburse your TSA PreCheck or Global Entry application fee once every four years",
    ],
    rewardsCash: false,
    rewardsSpending: [
      "5x points on prepaid hotels and car rentals booked directly in the Altitude Rewards Center",
      "3x points for every $1 on eligible travel purchases and mobile wallet spending",
      "1x points on all other eligible purchases",
    ],
    benefitTSA: true,
    tier: 3,
  },
  {
    display: "Wells Fargo - Active Cash",
    db: "wf_activeCash",
    fees: {
      annual: 0,
      foreign: 3,
    },
    link: 'https://creditcards.wellsfargo.com/active-cash-credit-card/',
    rewardsAdditional: [],
    rewardsCash: true,
    rewardsSpending: [
      "2% cash rewards on all purchases",
    ],
    tier: 0,
  },
  {
    display: "Wells Fargo - Autograph",
    db: "wf_autograph",
    fees: {
      annual: 0,
      foreign: 0,
    },
    link: 'https://creditcards.wellsfargo.com/autograph-visa-credit-card/',
    rewardsAdditional: [],
    rewardsCash: false,
    rewardsSpending: [
      "3X points for Restaurants - dining in, take-out, catering, delivery and more",
      "3X points for Travel - airfare, hotels, car rentals, cruises and more",
      "3X points for Gas - gas stations and electric vehicle charging stations",
      "3X points for Transit - subways, ride shares, parking, tolls and more",
      "3X points for Popular Streaming Services - content at home or on the go",
      "1X points on other purchases",
    ],
    tier: 3,
  },
  {
    display: "Wells Fargo - Autograph Journey",
    db: "wf_autographJourney",
    fees: {
      annual: 95,
      foreign: 0,
    },
    link: 'https://creditcards.wellsfargo.com/autograph-journey-visa-credit-card/',
    rewardsAdditional: [
      "$50 annual statement credit with $50 minimum airline purchase"
    ],
    rewardsCash: false,
    rewardsSpending: [
      "5X points on hotels",
      "4X points on airlines",
      "3X points on restaurants and other travel",
      "1X points on other purchases",
    ],
    tier: 3,
  },
]

export default cards
