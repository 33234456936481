import {useEffect} from 'react'

import {Typography, TextField, Button, Radio} from "@material-ui/core"
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'

import questions from '../../../constants/questions'
import {useStyles} from '../../../themes/styles'

const BankStatus = (templateData) => {
  const classes = useStyles()

  useEffect(() => {
    if (templateData.pageState.state.inputs.userInput.inputViews.boaAccountNew == null) {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: true,
      })
    }
    else {
      templateData.pageState.setState({
        ...templateData.pageState.state,
        disableNext: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const blurField = (field) => {
    let boa = templateData.pageState.state.inputs.userInput.boa
    const valNoComma = boa[field].replaceAll(',','')
    var valNumber = 0
    if (valNoComma[valNoComma.length - 1] === "k" || valNoComma[valNoComma.length - 1] === "K") {
      valNumber = Number(valNoComma) * 1000
    }
    else {
      valNumber = Number(valNoComma)
    }
    if (isNaN(valNumber)) {
      boa[field] = 0
    }
    else {
      boa[field] = Math.floor(valNumber)
    }
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const focusField = (field) => {
    let boa = templateData.pageState.state.inputs.userInput.boa
    const valField = boa[field]
    if (!valField || valField >= 100000) {
      boa[field] = ''
    }
    else {
      boa[field] = boa[field].toFixed(0)
    }
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const getVal = (field) => {
    const valField = templateData.pageState.state.inputs.userInput.boa[field]
    if (typeof(valField) == 'string') {
      return ("$" + valField)
    }
    else {
      if (valField >= 100000) {
        return ""
      }
      return ("$" + Math.floor(valField / 1000) + "k")
    }
  }

  const handleChangeFieldBoa = (field, val) => {
    let boa = templateData.pageState.state.inputs.userInput.boa
    if (val[0] !== '$') {
      boa[field] = ''
    }
    else {
      val = val.replaceAll('$','')
      boa[field] = val
    }
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const handleHaveAcct = (haveAcct) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.boaAccount = haveAcct
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const handleNewAcct = (haveAcct) => {
    let viewResponses = templateData.pageState.state.inputs.userInput.inputViews
    viewResponses.boaAccountNew = haveAcct
    templateData.pageState.setState({
      ...templateData.pageState.state,
      disableNext: false,
    })
  }

  const handleSelect = (field, val) => {
    let boa = templateData.pageState.state.inputs.userInput.boa
    boa[field] = val
    templateData.pageState.setState({
      ...templateData.pageState.state,
    })
  }

  const radioGte100k = (field) => {
    let boa = templateData.pageState.state.inputs.userInput.boa
    const valField = boa[field]
    if (typeof(valField) == 'number') {
      if (valField >= 100000) {
        return true
      }
    }
    return false
  }

  const radioOptions = (field) => {
    return <>
      <Stack direction="row" alignItems="center">
        <Radio
        color="primary"
        checked={radioGte100k(field)}
        onClick={() => handleSelect(field, 100000)}
        name="radio-buttons"
        />
        <Typography
        onClick={() => handleSelect(field, 100000)}
        className={classes.clickBlack}
        >
          $100k+
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="baseline">
        <Radio
        color="primary"
        checked={!radioGte100k(field)}
        onClick={() => handleSelect(field, 0)}
        value="b"
        name="radio-buttons"
        inputProps={{ 'aria-label': 'A' }}
        />
        <TextField
        id={field}
        variant="outlined"
        label="< $100k"
        value = {getVal(field)}
        onFocus = {() => focusField(field)}
        onChange = {(event => handleChangeFieldBoa(field, event.target.value))}
        onBlur={() => blurField(field)}
        />
      </Stack>
    </>
  }

  return <form>
    <Typography variant="h5">
      {questions.boaAccount}
      <FormGroup
      className={classes.spacing}
      >
        <Box
        component="span"
        className={classes.spreadBoxCenter}
        >
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.boaAccount === true ? "default" : "secondary"}
            onClick={() => handleHaveAcct(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color={templateData.pageState.state.inputs.userInput.inputViews.boaAccount === false ? "default" : "primary"}
            onClick={() => handleHaveAcct(true)}
          >
            Yes
          </Button>
        </Box>
        {templateData.pageState.state.inputs.userInput.inputViews.boaAccount == null ?
          <></>
        :
          <Box
          className={classes.spacing}
          >
            <Box
            className={classes.spacing}
            >
              {templateData.pageState.state.inputs.userInput.inputViews.boaAccount ?
                <>
                  <Box>
                    <Box>{questions.boaAccountBoa}</Box>
                    {radioOptions("cash")}
                  </Box>
                  <Box
                  className={classes.spacing}
                  >
                    <Box>{questions.boaAccountMerrill}</Box>
                    {radioOptions("investments")}
                  </Box>
                </>
              :
                <></>
              }
            </Box>
            <Box
            className={classes.spacing}
            >
              {templateData.pageState.state.inputs.userInput.inputViews.boaAccount ?
                <>{questions.boaAccountNew_add}</>
              :
                <>{questions.boaAccountNew_new}</>
              }
              <Box
              component="span"
              className={classes.spreadBoxCenter + " " + classes.spacing}
              >
                <Button
                  variant="contained"
                  color={templateData.pageState.state.inputs.userInput.inputViews.boaAccountNew === true ? "default" : "secondary"}
                  onClick={() => handleNewAcct(false)}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color={templateData.pageState.state.inputs.userInput.inputViews.boaAccountNew === false ? "default" : "primary"}
                  onClick={() => handleNewAcct(true)}
                >
                  Yes
                </Button>
              </Box>
              <Box
              className={classes.spacing}
              >
                {templateData.pageState.state.inputs.userInput.inputViews.boaAccountNew ?
                  <>
                    <Box>
                      {templateData.pageState.state.inputs.userInput.inputViews.boaAccount ?
                        <Box>{questions.boaAccountNewBoa_add}</Box>
                      :
                        <Box>{questions.boaAccountNewBoa_new}</Box>
                      }
                      {radioOptions("cashNew")}
                    </Box>
                    <Box
                    className={classes.spacing}
                    >
                    {templateData.pageState.state.inputs.userInput.inputViews.boaAccount ?
                      <Box>{questions.boaAccountNewMerrill_add}</Box>
                    :
                      <Box>{questions.boaAccountNewMerrill_new}</Box>
                    }
                      {radioOptions("investmentsNew")}
                    </Box>
                  </>
                :
                  <></>
                }
              </Box>
            </Box>
          </Box>
        }
      </FormGroup>
    </Typography>
  </form>
}

export default BankStatus
