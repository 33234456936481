import {Typography} from "@material-ui/core"
import Box from '@mui/material/Box'

import getCardInfo from '../../../fns/getCardInfo'

const CardsWallet = (inputs) => {
  return <Box>
    <Typography variant="h5">Cards you already have:</Typography>
    <Box>
      {inputs.cardsWallet.map((step, index) => (
        <Typography variant="body1">{getCardInfo(step).display}</Typography>
      ))}
    </Box>
  </Box>
}

export default CardsWallet
