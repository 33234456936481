import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"

import {Typography, TextField, Button} from "@material-ui/core"
import Stack from '@mui/material/Stack'

import PageAfterLogin from '../../constants/pageAfterLogin'
import {login} from "../../store/actions/authActions"
import {loadingToggle} from "../../store/actions/appActions"
import {useStyles} from '../../themes/styles'

const Login = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [creds, setCreds] = useState({
    email: "",
    password: "",
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute('6LfcNx0gAAAAALwCSmm2QFHUlyokNo7_i1iXTkX-', {action: 'submit'}).then(function(token) {
        dispatch(loadingToggle(true))
        dispatch(login(creds, props.socket, token))
        setCreds({
          ...creds,
          password: "",
        })
      })
    })
  }

  if (auth.name) {
    return <PageAfterLogin/>
  }

  return ( <>
    <form
      className={classes.shadowBox}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        id="enter-email"
        className={classes.spacing}
        label="Enter Email"
        variant="outlined"
        fullWidth
        value = {creds.email}
        onChange = {(event => setCreds({
          ...creds,
          email: event.target.value,
        }))}
      />
      <TextField
        id="enter-password"
        className={classes.spacing}
        type="password"
        label="Enter Password"
        variant="outlined"
        fullWidth
        value = {creds.password}
        onChange = {(event => setCreds({
          ...creds,
          password: event.target.value,
        }))}
      />
      <Button
        className={classes.spacing}
        variant="contained"
        color="primary"
        type="submit"
      >
        Sign in
      </Button>
      <Stack direction="row" spacing={1} className={classes.spacing}>
        <Typography variant="h6">Trouble logging in?</Typography>
        <Typography variant="h6" component={Link} to={"/recover-password"}>Reset password</Typography>
      </Stack>
      <Stack direction="row" spacing={1} className={classes.spacing}>
        <Typography variant="h6">Don't have an account yet?</Typography>
        <Typography variant="h6" component={Link} to={"/register"}>Create a free account</Typography>
      </Stack>
    </form>
  </> )
}

export default Login
