import {server} from "./server"

export const urlServer = server

export const urlApi = (urlServer + "/api")

export const setHeaders = () => {
  const header = {
    headers: {}
  }
  return header
}
