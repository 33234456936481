import Box from '@mui/material/Box'

import PurchaseInput from '../../common/PurchaseInput'

const PurchasesTravelPrimary = (templateData) => {
  const inputs = [
    {
      db: "travel_airfare",
      disp: "Airfare",
    },
    {
      db: "travel_hotels",
      disp: "Hotels",
    },
    {
      db: "travel_carRentals",
      disp: "Car Rentals",
    },
    {
      db: "travel_cruises",
      disp: "Cruises",
    },
    {
      db: "travel_travelAgencies",
      disp: "Additional Travel Agency Expenses",
    },
  ]

  return <Box
  component={PurchaseInput}
  inputs={inputs}
  pageState={templateData.pageState}
  />
}

export default PurchasesTravelPrimary
