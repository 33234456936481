import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {generatePath} from "react-router"
import {Link, Redirect, useHistory} from "react-router-dom"

import axios from "axios"
import moment from "moment"
import {toast} from "react-toastify"

import {ThemeProvider} from '@mui/material/styles'
import IconGift from '@material-ui/icons/CardGiftcard'
import IconGo from '@material-ui/icons/DoubleArrow'
import IconRestart from '@material-ui/icons/Replay'
import Button from "@material-ui/core/Button"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import {urlApi} from "../../api"
import {loadingToggle} from "../../store/actions/appActions"
import theme from '../../themes/_main'
import {useStyles} from '../../themes/styles'

import DividerMargin from '../styled/DividerMargin'
import DividerMarginMulti from '../styled/DividerMarginMulti'

import DialogGift from './purchaseViews/dialogGift'
import DialogNoSurvey from './purchaseViews/dialogNoSurvey'
import DialogReset from './purchaseViews/dialogReset'

const humanReadableFromTier = (tier) => {
  if (tier === undefined) {
    return "ANALYSIS"
  }
  const type = (tier?.fullAnalysis ? "Full Deck" : "Next Card")
  return (type + " Analysis")
}

const Purchase = function DotsMobileStepper(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [analysisType, setAnalysisType] = React.useState("")
  const [openGift, setOpenGift] = React.useState("")
  const [openNoSurvey, setOpenNoSurvey] = React.useState(false)
  const [openReset, setOpenReset] = React.useState("")
  const state = {
    checkingOut: false,
    fullAnalysis: true,
    redirection: {},
    tierLevel: 0,
  }
  const [pollfishLoaded, setPollfishLoaded] = React.useState(false)
  const [purchases, setPurchases] = React.useState([])
  const [fullCount, setFullCount] = React.useState(0)
  const [nextCount, setNextCount] = React.useState(0)
  const strAnalysis = ""

  const disabledButton_modify = (purchase) => {
    return (!purchase.isStarted && purchase.isGift)
  }

  const handleModify = async (purchase) => {
    if (purchase.isStarted) {
      await setOpenReset(purchase._id)
    }
  }

  const launchSurvey = (analysisTypeClick) => {
    if (analysisTypeClick === analysisType) {
      window.Pollfish.showFullSurvey()
    }
    else {
      dispatch(loadingToggle(true))
      setPollfishLoaded(false)
      setAnalysisType(analysisTypeClick)
      var pollfishConfig = {
        api_key: "3d4fd121-8ecb-4c14-a91f-0b4ac35816ba",
        user_id: auth._id,
        placement_key: "74194249-6908-4bf4-a369-c2ae4619aab2",
        // debug: true,
        clickId: `${analysisTypeClick}__${auth._id}__${props.socket.id}`,
        ready: customReady,
        closeCallback: customSurveyClosed,
        userNotEligibleCallback: customUserNotEligible,
        closeAndNoShowCallback: customCloseAndNoShow,
        surveyCompletedCallback: customSurveyFinished,
        surveyAvailable: customSurveyAvailable,
        surveyNotAvailable: customSurveyNotAvailable,
      }
      if (analysisType) {
        window.Pollfish.restart(pollfishConfig)
      }
      else {
        window.Pollfish.start(pollfishConfig)
      }

      // Survey is ready
      function customReady(){
        dispatch(loadingToggle(false))
        setPollfishLoaded(true)
      }

      // User closed the survey
      function customSurveyClosed(){}

      // User is not eligible
      function customUserNotEligible(){
        noSurvey()
      }

      // Survey has been completed
      function customSurveyFinished(data){
        setAnalysisType("completed")
      }

      // Close and hide the Pollfish panel
      function customCloseAndNoShow(){}

      // Survey is available
      function customSurveyAvailable(data){
        window.Pollfish.showFullSurvey()
      }

      // Survey not available
      function customSurveyNotAvailable(){
        noSurvey()
      }
    }
  }

  const noSurvey = () => {
    setAnalysisType("no survey")
    setOpenNoSurvey(true)
  }

  if (!auth.name && purchases.length) {
    //setPurchases([])
  }

  const updatePurchases = (updatedData) => {
    let cntFull = 0
    let cntNext = 0
    for (var iPurchase = 0; iPurchase < updatedData.length; iPurchase++) {
      if (updatedData[iPurchase].tier.fullAnalysis) {
        cntFull++
      }
      else {
        cntNext++
      }
    }
    setPurchases(updatedData)
    setNextCount(cntNext)
    setFullCount(cntFull)
  }


  useEffect(() => {
    props.socket.on('pollfishCallbackComplete', (id) => {
      history.push(`/analysis/${id}`)
    })

    props.socket.on('purchases', (updatedPurchases) => {
      updatePurchases(updatedPurchases)
    })

    if (auth.name) {
      dispatch(loadingToggle(true))
      axios
      .post(`${urlApi}/analysis/getPurchases`, {}, {"withCredentials": true})
      .then(resp => {
        updatePurchases(resp.data)
        dispatch(loadingToggle(false))                  
        const script_jquery = document.createElement('script')
        script_jquery.setAttribute("id", "script_jquery")
        script_jquery.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"
        script_jquery.async = true
        script_jquery.onload = () => {
          const script_pollfish = document.createElement('script')
          script_pollfish.setAttribute("id", "script_pollfish")
          script_pollfish.src = "https://storage.googleapis.com/pollfish_production/sdk/webplugin/pollfish.min.js"
          script_pollfish.async = true
          script_pollfish.onload = () => {
            setPollfishLoaded(true)
          }
          document.body.appendChild(script_pollfish)
        }
        document.body.appendChild(script_jquery)
      })
      .catch(error => {
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        })
        dispatch({
          type: "LOADING_TOGGLE",
          isLoading: false,
        })
      })
    }
    else {
      updatePurchases([])
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.name])

  if (!auth.name) {
    return (
      <Typography variant="h5">
        You must <Link to="/login">log in</Link> or <Link to="/register">create a free account</Link> to access.
    </Typography>
    )
  }

  if (state["redirection"]._id) {
    return <Redirect to={{
      pathname: generatePath("/analysis/:idAnalysis", {idAnalysis: state["redirection"]._id}),
      location: state["redirection"],
    }}/>
  }
  else if (state["checkingOut"]) {
    return (
      <>
      </>
    )
  }
  else {
    return (
      <Box>
        <ThemeProvider theme={theme}>
          <DialogGift open={openGift} setOpen={setOpenGift} strAnalysis={strAnalysis}/>
          <DialogNoSurvey open={openNoSurvey} setOpen={setOpenNoSurvey}/>
          <DialogReset open={openReset} setOpen={setOpenReset}/>
          {(fullCount === 0 || nextCount === 0) ?
            <div>
              <Typography variant="h6">
                Support us by taking a brief third-party survey to gain access to your free credit card analysis
              </Typography>
              {(nextCount === 0) ?
                <div>
                  <Button
                    className={classes.spacing}
                    variant="contained"
                    color="primary"
                    onClick={() => launchSurvey("next")}
                    disabled={!pollfishLoaded}
                  >
                    Next Card Analysis
                    <IconGo className={classes.spacingLeft}/>
                  </Button>
                  <br/>
                  <br/>
                  </div>
              :
                <></>
              }
              {(fullCount === 0) ?
                <div>
                  <Button
                    className={classes.spacing}
                    variant="contained"
                    color="primary"
                    onClick={() => launchSurvey("full")}
                    disabled={!pollfishLoaded}
                  >
                    Full Deck Analysis
                    <IconGo className={classes.spacingLeft}/>
                  </Button>
                  <br/>
                  <br/>
                </div>
              :
                <></>
              }
              <DividerMarginMulti/>
            </div>
          :
            <div></div>
          }
          {purchases.length ?
            <div>
              <Typography variant="h4" sx={{}}>Open Analyses</Typography>
              <DividerMargin/>
              {purchases.map((step, index) => (
                <Box className={classes.spacing} key={"purchase_" + index}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">{humanReadableFromTier(step.tier)}</Typography>
                  </Stack>
                  <Typography variant="h6">{"Initialized " + moment(step.datePurchased).fromNow()}</Typography>
                  <Stack direction="row" justifyContent="space-between">
                    {step.isStarted ? 
                      <Button
                        className={classes.spacing + " " + classes.colorLight}
                        variant="contained"
                        onClick={() => handleModify(step)}
                        disabled={disabledButton_modify(step)}
                      >
                        {step.isStarted ?
                          <>
                            <IconRestart className={classes.spacingRight}/>
                            {"Reset"}
                          </>
                        :
                          <>
                            <IconGift className={classes.spacingRight}/>
                            {"Give as Gift"}
                          </>
                        }
                      </Button>
                    :
                      <div></div>
                    }
                    
                    <Button
                      className={classes.spacing}
                      variant="contained"
                      color="primary"
                      component={Link} to={"/analysis/" + step._id}
                    >
                      {step.isStarted ? "Continue " : "Go!"}
                      <IconGo className={classes.spacingLeft}/>
                    </Button>
                  </Stack>
                  <DividerMargin/>
                </Box>
              ))}
            </div>
          :
            <></>
          }
        </ThemeProvider>
      </Box>
    )
  }
}

export default Purchase
